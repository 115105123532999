import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { MsalGuard } from './core/auth/guards/msal.guard';
import { LoaderComponent } from './loader/loader.component';


// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'support/sign-in' },
    { path: 'loading', component: LoaderComponent },
    // Redirect signed in user to the '/example'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'loading' },
    // { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboards/project' },
    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'support', loadChildren: () => import('app/modules/admin/pages/support/support.module').then(m => m.SupportModule) },
            { path: 'outcomes-web', loadChildren: () => import('app/modules/admin/pages/outcomes-web/outcomes.module').then(m => m.OutcomesModule) },
            { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule) },
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule) },
            { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule) },
            { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule) },
            //{ path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule) },
            {
                // Needed for hash routing
                path: 'error',
                loadChildren: () => import('app/modules/auth/azure-ad-b2-c-callback/azure-ad-b2-c-callback.module').then(m => m.AzureADB2CCallbackModule)
            },
            {
                // Needed for hash routing
                path: 'state',
                loadChildren: () => import('app/modules/auth/azure-ad-b2-c-callback/azure-ad-b2-c-callback.module').then(m => m.AzureADB2CCallbackModule)
            },
            {
                // Needed for hash routing
                path: 'code',
                loadChildren: () => import('app/modules/auth/azure-ad-b2-c-callback/azure-ad-b2-c-callback.module').then(m => m.AzureADB2CCallbackModule)
            },

            {
                path: 'azure-ad2bc-callback',
                loadChildren: () => import('app/modules/auth/azure-ad-b2-c-callback/azure-ad-b2-c-callback.module').then(m => m.AzureADB2CCallbackModule)
            }
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],

        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule) },
            { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule) }
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule) },
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [MsalGuard],
        //canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'dashboards', children: [
                    { path: 'project', loadChildren: () => import('app/modules/admin/dashboards/project/project.module').then(m => m.ProjectModule) },
                ]
            },
            {

                path: 'case-management', loadChildren: () => import('app/modules/admin/pages/case-management/case-management.module').then(m => m.CaseManagementModule),


            },
            {

                path: 'manage-certificate', loadChildren: () => import('app/modules/admin/pages/certificate-management/certificate-management.module').then(m => m.CertificateManagementModule),


            },
            {

                path: 'assessment', loadChildren: () => import('app/modules/admin/pages/profile/profile.module').then(m => m.ProfileModule),


            },
            {

                path: 'outcomes-output', loadChildren: () => import('app/modules/admin/pages/assessments/outcomes/outcomes.module').then(m => m.OutcomesModule),


            },
            {

                path: 'profile', loadChildren: () => import('app/modules/admin/pages/profile/profile.module').then(m => m.ProfileModule),


            },
            {

                path: 'my-profile', loadChildren: () => import('app/modules/admin/pages/my-profile/my-profile.module').then(m => m.MyProfileModule),


            },
            {

                path: 'assessments', loadChildren: () => import('app/modules/admin/pages/assessments/assessments.module').then(m => m.AssessmentsModule),


            },
            {

                path: 'application', loadChildren: () => import('app/modules/admin/pages/applications/applications.module').then(m => m.ApplicationsModule),


            },
            {

                path: 'adp-decisions', loadChildren: () => import('app/modules/admin/pages/adp-decisions/adp-decisions.module').then(m => m.AdpDecisionsModule),


            },
            {

                path: 'legacy-data', loadChildren: () => import('app/modules/admin/pages/legacy-data/legacy-data.module').then(m => m.LegacyDataModule),


            },
            {

                path: 'decision-output', loadChildren: () => import('app/modules/admin/pages/adp-decisions/review-adp-decisions/review-adp-decisions.module').then(m => m.ReviewAdpDecisionsModule),

            },
            {

                path: 'endorsed-schemes', loadChildren: () => import('app/modules/admin/pages/endorsed-schemes/endorsed-schemes.module').then(m => m.EndorsedSchemesModule),


            },
            { path: 'schedules', loadChildren: () => import('app/modules/admin/pages/schedulers/schedulers.module').then(m => m.SchedulersModule) },
            {

                path: 'community', loadChildren: () => import('app/modules/admin/pages/community/community.module').then(m => m.CommunityModule),


            },
            {

                path: 'task-management', loadChildren: () => import('app/modules/admin/pages/task-management/task-management.module').then(m => m.TaskManagementModule),


            },
            {

                path: 'invoice', loadChildren: () => import('app/modules/admin/pages/invoice/invoice.module').then(m => m.InvoiceModule),


            },
            { path: 'example', loadChildren: () => import('app/modules/admin/example/example.module').then(m => m.ExampleModule) },
        ]
    },

    // 404 & Catch all
    { path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/admin/pages/error/error-404/error-404.module').then(m => m.Error404Module) },
    { path: 'unauthorized/:email', pathMatch: 'full', loadChildren: () => import('app/modules/admin/pages/error/unauthorized401/unauthorized401.module').then(m => m.Unauthorized401Module) },
    { path: '**', redirectTo: '404-not-found' }
];
