<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation *ngIf="outcomeOutput" class="dark bg-gray-900 print:hidden" [appearance]="'compact'" [mode]="isScreenSmall ? 'over' : 'side'" [name]="'mainNavigation'" [navigation]="navigation.compact" [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center justify-center h-20 mt-3 mb-4">
            <img class="w-20" src="assets/images/logo/Light Background Logo.png" alt="Logo image">
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
    
    <!-- <div 
    class="flex p-2 items-center text-lg font-bold justify-center tracking-tight leading-6 truncate text-white bg-red-500">SANDBOX TEST ENVIRONMENT</div> -->
    <!-- Header -->
    <div style="position: relative; z-index: 1 !important;" class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button *ngIf="outcomeOutput" mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        <img *ngIf="!outcomeOutput" class="w-13" src="assets/images/logo/Light Background Logo.png" alt="Logo image">
      
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
           <!-- <languages></languages>  -->
            <!-- <button mat-icon-button matTooltip="User Documentation"><a routerLink="/help"><mat-icon svgIcon="feather:help-circle"></mat-icon></a>  </button> -->
            <!-- <button mat-icon-button matTooltip="Feedback"><a href="https://feedback.jas-anz.org" target="_blank"><mat-icon svgIcon="mat_solid:feedback"></mat-icon></a> </button> -->
          <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            
            <mat-form-field class="cus-dropdown" appearance="fill" *ngIf="isCabRole && outcomeOutput">
                <mat-select [formControl]="switchControl" (selectionChange)="switchAccount($event.value)">
                    <mat-optgroup *ngFor="let group of switchGroups" [label]="group.name">
                        <mat-option *ngFor="let switch of group.switchaccount" [value]="switch.id">
                            {{switch.name}}
                        </mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
            <notifications *ngIf="outcomeOutput"></notifications>
            <!-- <search [appearance]="'bar'"></search>
            <shortcuts></shortcuts>
            <messages></messages>
            <notifications></notifications>
            <button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
            </button>-->
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div style="position: relative; z-index: 0 !important;" class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary"><a href="https://www.jas-anz.org" target="_blank" matTooltip="JAS-ANZ Link">JAS-ANZ &copy; {{currentYear}}</a></span>
 </div>

</div>

<!-- Quick chat -->
<!--<quick-chat #quickChat="quickChat"></quick-chat>-->