/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */
 

 import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';

 const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
 
 /**
  * Enter here the user flows and custom policies for your B2C application,
  * To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
  * To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
  */
 export const b2cPolicies = {
     names: {
     signIn: "b2c_1_signin",
     },
     authorities: {
         signIn: {
         authority: "https://login.microsoftonline.com/common/",
         },
     },
   authorityDomain: "jasanzservicesbeta.b2clogin.com"
 };
 
 /**
  * Configuration object to be passed to MSAL instance on creation. 
  * For a full list of MSAL.js configuration parameters, visit:
  * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
  */
  export const msalConfig: Configuration = {
     auth: {
      clientId:'289b7ccc-f50b-4645-8e58-01c0bfe92188',
      //clientId:'070cffda-7d38-4c38-a29a-f43d9a7bc7f0', //Redux Dev App Id(B2C)
      //clientId:'3c69a338-5275-45f6-a4c3-ccd6f7abc44c',//Redux UAT App Id(B2C)
      authority: b2cPolicies.authorities.signIn.authority, // Defaults to "https://login.microsoftonline.com/common"
         knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
          redirectUri: '/support/sign-in', // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
          postLogoutRedirectUri:'/sign-out'
     },
     cache: {
         //cacheLocation: BrowserCacheLocation.localStorage, // Configures cache location. "localStorage" is more secure, but "localStorage" gives you SSO between tabs.
         cacheLocation: 'localStorage',
         storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
     },
     system: {
         loggerOptions: {
             loggerCallback(logLevel: LogLevel, message: string) {
             },
             logLevel: LogLevel.Verbose,
             piiLoggingEnabled: false
         }
     }
 }

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
 export const loginRequest = {
    scopes: ['user.read'],
    prompt: "select_account"
  };
