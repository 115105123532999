// import { Manufacturing } from './../../modules/admin/pages/models/models.component';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { CommonService } from '../../service/common.service';
import { StaticResourcesService } from 'app/service/static-resources.service';
// import { category } from 'assets/config/categoryPermission';
@Injectable()
export class AuthService {
    public _authenticated: boolean = false;
    categoryPermissionList: any;
    // public apiUrl = environment.api;
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        private _commonService: CommonService,
        private _staticService: StaticResourcesService
    ) {

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }
    get accessToken(): string {
        return localStorage.getItem('accessToken');
    }
    set azureUserProfile(azureUserProfile: string) {
        localStorage.setItem('azureUserProfile', azureUserProfile);
    }

    get azureUserProfile(): any {
        return localStorage.getItem('azureUserProfile');
    }
    set userProfile(userProfile: string) {
        localStorage.setItem('userProfile', userProfile);
    }

    get userProfile(): any {
        return localStorage.getItem('userProfile');
    }

    set switchAccount(switchAccount: string) {
        localStorage.setItem('switchAccount', switchAccount);
    }

    get switchAccount(): any {
        return localStorage.getItem('switchAccount');
    }

    set setMarketTrends(marketTrends: any) {
        localStorage.setItem('marketTrends', marketTrends);
    }
    get getMarketTrends(): any {
        return localStorage.getItem('marketTrends');
    }

    set upsertCertificate(upsertCertificate: string) {
        localStorage.setItem('upsertCertificate', upsertCertificate);
    }

    get upsertCertificate(): any {
        return localStorage.getItem('upsertCertificate');
    }

    set basicInformation(basicInformation: string) {
        localStorage.setItem('basicInformation', basicInformation);
    }

    get basicInformation(): any {
        return localStorage.getItem('basicInformation');
    }

    set audits(audits: string) {
        localStorage.setItem('audits', audits);
    }

    get audits(): any {
        return localStorage.getItem('audits');
    }

    set auditsTeamMembers(auditsTeamMembers: string) {
        localStorage.setItem('auditsTeamMembers', auditsTeamMembers);
    }

    get auditsTeamMembers(): any {
        return localStorage.getItem('auditsTeamMembers');
    }

    set sites(sites: string) {
        localStorage.setItem('sites', sites);
    }

    get sites(): any {
        return localStorage.getItem('sites');
    }

    set categories(categories: string) {
        localStorage.setItem('categories', categories);
    }

    get categories(): any {
        return localStorage.getItem('categories');
    }

    set models(models: string) {
        localStorage.setItem('models', models);
    }

    get models(): any {
        return localStorage.getItem('models');
    }

    set manufacturingSites(manufacturingSites: string) {
        localStorage.setItem('manufacturingSites', manufacturingSites);
    }

    get manufacturingSites(): any {
        return localStorage.getItem('manufacturingSites');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any> {

        // Throw error, if the user is already logged in
        if (this._authenticated) {

            return throwError('User is already logged in.');
        }

        return this._httpClient.post('api/auth/sign-in', credentials).pipe(
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.accessToken;


                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;
                //localStorage.setItem("permissionDtos", JSON.stringify(response?.rolePermissionSet));
                // Return a new observable with the response
                return of(response);
            })
        );
    }
    postRequest(method: string, body: any) {

        return this._httpClient.post(this._commonService.AuthAPIURL + method, body).pipe(
            switchMap((response: any) => {
                if (response.isSuccessful) {

                    // Store the access token in the local storage
                    this.accessToken = response.data.authenticationToken?.accessToken;
                    // Set the authenticated flag to true
                    this._authenticated = true;
                    // Store the user on the user service
                    this.userProfile = JSON.stringify(response.data?.userProfile);
                    var userData = JSON.parse(this.userProfile);
                    //localStorage.setItem("permissionDtos", JSON.stringify(response?.rolePermissionSet));
                    var schemeDataObj;
                    var cabDataObj;

                    // Static roles and module call from static resource service
                    // this._staticService.getAllRoleList();

                    // Last logout dose not exist
                    if (userData?.jasanz_agencyid == null) {
                        if (userData.cabsDtos != null) {
                            this._commonService.name = userData.cabsDtos[0]?.abbreviation;

                            cabDataObj = {
                                "jasanz_agencytype": userData.cabsDtos[0]?.type,
                                "jasanz_agencyid": userData.cabsDtos[0]?.id,
                                "cab_name": userData.cabsDtos[0]?.schemes_name,
                                "requiresextrascrutiny": userData.cabsDtos[0]?.requiresextrascrutiny,
                                "certificatefileuploadmethod": userData.cabsDtos[0]?.certificatefileuploadmethod,
                                "logo": userData.cabsDtos[0]?.logo,
                                "abbreviation": userData.cabsDtos[0]?.abbreviation
                            }
                            this.switchAccount = JSON.stringify(cabDataObj);
                        }
                        if (userData.schemesDto != null) {
                            this._commonService.name = userData.schemesDto[0]?.abbreviation;
                            schemeDataObj = {
                                "jasanz_agencytype": userData.schemesDto[0]?.type,
                                "jasanz_agencyid": userData.schemesDto[0]?.id,
                                "cab_name": userData.schemesDto[0]?.schemes_name,
                                "requiresextrascrutiny": false,
                                "certificatefileuploadmethod": false,
                                "logo": userData.schemesDto[0]?.logo,
                                "abbreviation": userData.schemesDto[0]?.abbreviation
                            }
                            this.switchAccount = JSON.stringify(schemeDataObj);
                        }

                    }
                    // Last logout dose exist
                    else {

                        //schemes not null  
                        if (userData.schemesDto != null && userData.jasanz_agencytype == 937790001 && userData.cabsDtos == null) {

                            let scheme = userData.schemesDto.find(x => x.id === userData.jasanz_agencyid);
                            //Last logout scheme will log-in
                            if (scheme) {
                                this._commonService.name = scheme.abbreviation;
                                schemeDataObj = {
                                    "jasanz_agencytype": scheme.type,
                                    "jasanz_agencyid": scheme.id,
                                    "cab_name": scheme.schemes_name,
                                    "requiresextrascrutiny": false,
                                    "certificatefileuploadmethod": false,
                                    "logo": null,
                                    "abbreviation": scheme.abbreviation
                                }
                            }

                            else {
                                //First scheme will login
                                this._commonService.name = userData.schemesDto[0]?.abbreviation;
                                schemeDataObj = {
                                    "jasanz_agencytype": userData.schemesDto[0]?.type,
                                    "jasanz_agencyid": userData.schemesDto[0]?.id,
                                    "cab_name": userData.schemesDto[0]?.schemes_name,
                                    "requiresextrascrutiny": false,
                                    "certificatefileuploadmethod": false,
                                    "logo": null,
                                    "abbreviation": userData.schemesDto[0]?.abbreviation
                                }

                            }
                            this.switchAccount = JSON.stringify(schemeDataObj);

                        }
                        //Scheme and Cab not null
                        if (userData.cabsDtos != null && userData.schemesDto != null && userData.jasanz_agencytype == 937790001) {

                            let cab = userData.cabsDtos.find(x => x.id === userData.jasanz_agencyid);
                            if (cab) {
                                this._commonService.name = cab.abbreviation;
                                cabDataObj = {
                                    "jasanz_agencytype": cab.type,
                                    "jasanz_agencyid": cab.id,
                                    "cab_name": cab.cab_name,
                                    "requiresextrascrutiny": cab.requiresextrascrutiny,
                                    "certificatefileuploadmethod": cab.certificatefileuploadmethod,
                                    "abbreviation": cab.abbreviation,
                                    "logo": cab.logo
                                }
                            }
                            else {
                                this._commonService.name = userData.cabsDtos[0]?.abbreviation;
                                cabDataObj = {
                                    "jasanz_agencytype": userData.cabsDtos[0]?.type,
                                    "jasanz_agencyid": userData.cabsDtos[0]?.id,
                                    "cab_name": userData.cabsDtos[0]?.cab_name,
                                    "requiresextrascrutiny": userData.cabsDtos[0]?.requiresextrascrutiny,
                                    "certificatefileuploadmethod": userData.cabsDtos[0]?.certificatefileuploadmethod,
                                    "abbreviation": userData.cabsDtos[0]?.abbreviation,
                                    "logo": userData.cabsDtos[0]?.logo
                                }

                            }
                            this.switchAccount = JSON.stringify(cabDataObj);
                        }
                        //Cab not null
                        if (userData.cabsDtos != null && userData.jasanz_agencytype == 937790000) {

                            let cab = userData.cabsDtos.find(x => x.id === userData.jasanz_agencyid);
                            if (cab) {
                                this._commonService.name = cab.abbreviation;
                                cabDataObj = {
                                    "jasanz_agencytype": cab.type,
                                    "jasanz_agencyid": cab.id,
                                    "cab_name": cab.cab_name,
                                    "requiresextrascrutiny": cab.requiresextrascrutiny,
                                    "certificatefileuploadmethod": cab.certificatefileuploadmethod,
                                    "abbreviation": cab.abbreviation,
                                    "logo": cab.logo
                                }
                            }
                            else {
                                this._commonService.name = userData.cabsDtos[0]?.abbreviation;
                                cabDataObj = {
                                    "jasanz_agencytype": userData.cabsDtos[0]?.type,
                                    "jasanz_agencyid": userData.cabsDtos[0]?.id,
                                    "cab_name": userData.cabsDtos[0]?.cab_name,
                                    "requiresextrascrutiny": userData.cabsDtos[0]?.requiresextrascrutiny,
                                    "certificatefileuploadmethod": userData.cabsDtos[0]?.certificatefileuploadmethod,
                                    "abbreviation": userData.cabsDtos[0]?.abbreviation,
                                    "logo": userData.cabsDtos[0]?.logo
                                }

                            }
                            this.switchAccount = JSON.stringify(cabDataObj);
                        }

                    }
                }

                // Return a new observable with the response
                return of(response);
            })
        );
    }


    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {

        // Renew token
        return this._httpClient.post('api/auth/refresh-access-token', {
            accessToken: this.accessToken
        }).pipe(
            catchError(() =>

                // Return false
                of(false)
            ),
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.accessToken;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // Return true
                return of(true);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {

        // clear the local storage
        localStorage.clear();
        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    removeLocalStorage(): Observable<any> {
        localStorage.removeItem('basicInformation');
        localStorage.removeItem('audits');
        localStorage.removeItem('auditsTeamMembers');
        localStorage.removeItem('sites');
        localStorage.removeItem('categories');
        localStorage.removeItem('models');
        localStorage.removeItem('manufacturingSites');
        return of(true);
    }
    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { firstName: string; lastName: string; email: string; password: string; }): Observable<any> {
        return this._httpClient.post('https://reduxauthservice-dev.jas-anz.org/api/v1/Auth/CreateUser', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {

        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    setPermissions(permissions: any) {
        localStorage.setItem('permissionDtos', JSON.stringify(permissions));
    }

    // hasPermission(permission) {
    //     let role: any;
    //     let roleFlag = false;
    //     let moduleFlag = false;
    //     let accessControlFlag = false;
    //     let accessControlsDto = JSON.parse(localStorage.getItem("userProfile"))?.accessControlsDto;
    //     let roleDto = JSON.parse(localStorage.getItem("userProfile"))?.roleDto;
    //     let cabsDtos = JSON.parse(localStorage.getItem("userProfile"))?.cabsDtos;
    //     if (permission.Role.length > 0) {
    //         permission.Role.forEach((element) => {
    //             role = roleDto.find(ele => ele.id === element);
    //             if (role) {
    //                 roleFlag = true;
    //             }
    //         })
    //     }

    //     if (permission.Role.length > 0 && cabsDtos) {
    //         permission.Role.forEach((element) => {
    //             role = roleDto.find(ele => ele.id === element);
    //             if (role) {
    //                 roleFlag = true;
    //             }
    //         })
    //     }

    //     if (permission.Module) {
    //         
    //         let moduleAccessList = accessControlsDto.filter(ele => ele.moduleId === permission.Module);
    //         if (moduleAccessList.length) {
    //             moduleFlag = true;
    //             moduleAccessList.forEach(element => {
    //                 permission.accessControl.forEach(elem => {
    //                     switch (elem) {
    //                         case 'VIEW': {
    //                             if (element.accessControlView) accessControlFlag = true;
    //                             break;
    //                         }
    //                         case 'DELETE': {
    //                             if (element.accessControlDelete) accessControlFlag = true;
    //                             break;
    //                         }
    //                         case 'CREATE': {
    //                             if (element.accessControlCreate) accessControlFlag = true;
    //                             break;
    //                         }
    //                         case 'EDIT': {
    //                             if (element.accessControlEdit) accessControlFlag = true;
    //                             break;
    //                         }
    //                         default: {
    //                             accessControlFlag = false;
    //                             break;
    //                         }
    //                     }
    //                 })
    //             });
    //         }
    //     }
    //     return permission.isReverse ? (roleFlag && moduleFlag && accessControlFlag) : !(roleFlag && moduleFlag && accessControlFlag) || permission.isReverse ? (roleFlag && cabsDtos) : !(roleFlag && cabsDtos);
    // }

    hasPermission(permission) {
        let moduleFlag = false;
        let accessControlFlag = false;
        let accessControlsDto = JSON.parse(localStorage.getItem("userProfile"))?.accessControlsDto;

        if (permission.Module) {
            let moduleAccessList;
            permission.Module.forEach(element => {
                moduleAccessList = accessControlsDto.filter(ele => ele.moduleId === element);
            });

            if (moduleAccessList.length) {
                moduleFlag = true;
                moduleAccessList.forEach(element => {
                    permission.accessControl.forEach(elem => {
                        switch (elem) {
                            case 'VIEW': {
                                if (element.accessControlView) accessControlFlag = true;
                                break;
                            }
                            case 'DELETE': {
                                if (element.accessControlDelete) accessControlFlag = true;
                                break;
                            }
                            case 'CREATE': {
                                if (element.accessControlCreate) accessControlFlag = true;
                                break;
                            }
                            case 'EDIT': {
                                if (element.accessControlEdit) accessControlFlag = true;
                                break;
                            }
                            default: {
                                accessControlFlag = false;
                                break;
                            }
                        }
                    })
                });
            }
        }
        return moduleFlag && accessControlFlag
    }

    hasCategory(permission) {
        let categoryFlag = false;
        let roleList = this._staticService.roleList;
        let userDetail = JSON.parse(localStorage.getItem("userProfile"));
        let isTrRole = userDetail?.roleDto?.find((x: any) => x.id === roleList.Technical_Resource);

        this._staticService.masterData.subscribe((res) => {
            this.categoryPermissionList = JSON.parse(res.rolePremissionJsonData);
          })
          
        permission.forEach((item) => {
            categoryFlag = false;
            let atmList = item.atmList;
            let categor = item.key;
            if (categor) {
                if (isTrRole) {
                    this.categoryPermissionList[categor]?.forEach((elem) => {
                        atmList?.forEach((element) => {
                            if (elem?.RoleId === element?.id) {
                                categoryFlag = true;
                            }
                        })
                    })
                }
            }
            else {
                categoryFlag = false;
            }

            if (item.isShow) {
                categoryFlag = categoryFlag;
            } else {
                categoryFlag = !categoryFlag;
            }
        })

        return categoryFlag;
    }

    isAuthorized() {
        // if (AuthUtils.isTokenExpired(this.accessToken)) {
        return localStorage.getItem("userProfile");
        // }
    }
}