import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { CommonService } from 'app/service/common.service';
import { StaticResourcesService } from 'app/service/static-resources.service';

@Directive({
  selector: '[appCategoryPermission]'
})
export class CategoryPermissionDirective {
  roleList: any;
  moduleList: any;
  categoryList: any;
  categoryPermissionList: any;
  userDetail: any;
  switchAccount: any;
  isCabRole: any;
  isTrRole: any;
  atmList: any;

  constructor(
    private templateRef: TemplateRef<any>,
    private authService: AuthService,
    private commonService: CommonService,
    private viewContainer: ViewContainerRef,
    private _staticService: StaticResourcesService
  ) { }
  userPermissions!: any

  @Input()
  set appCategoryPermission(permissions: Permissions) {

    if (!permissions) {
      throw new Error('Permissions value is empty or missed');
    }
    this.userPermissions = permissions;
  }

  ngOnInit() {
    this.roleList = this._staticService.roleList;
    this.moduleList = this._staticService.moduleList;
    this.categoryList = this._staticService.categoryList;

    this.atmList = this.commonService.atmList

    this._staticService.masterData.subscribe((res) => {
      this.categoryPermissionList = JSON.parse(res.rolePremissionJsonData);
      console.log("categoryPermissionList", this.categoryPermissionList)
    })

    this.userDetail = JSON.parse(localStorage.getItem("userProfile"));
    this.switchAccount = JSON.parse(localStorage.getItem("switchAccount"));

    this.isCabRole = this.userDetail?.roleDto?.find((x: any) => x.id === this.roleList.CAB);
    this.isTrRole = this.userDetail?.roleDto?.find((x: any) => x.id === this.roleList.Technical_Resource);

    let hasAccess = false;
    if (this.authService.isAuthorized() && this.userPermissions) {
      hasAccess = this.authService.hasCategory(this.userPermissions);
    }

    if (hasAccess) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

}
