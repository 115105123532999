import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'app/service/common.service';
import { ToastrService } from 'ngx-toastr';
import { Roles } from 'app/models/master-role';
import { Modules } from 'app/models/master-module';

@Component({
  selector: 'app-comment-edit',
  templateUrl: './comment-edit.component.html',
  styleUrls: ['./comment-edit.component.scss']
})
export class CommentEditComponent implements OnInit {

  // commentsForm = new FormGroup({

  //   message: new FormControl(''),

  // })
  commentsForm: FormGroup;
  submitted: boolean;
  permissionList: any;
  permissionSet: any;
  updatedPermissionList: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,

    public matDialogRef: MatDialogRef<CommentEditComponent>,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private _commonService: CommonService, private cd: ChangeDetectorRef) {

  }



  ngOnInit() {
    const userDetail = JSON.parse(localStorage.getItem("userProfile"));
    const allPermissions = userDetail.accessControlsDto;
    if (allPermissions.length > 0) {
      let allPermission = allPermissions.filter(item => item.moduleId === Modules.CASE_MNGT);
      this.updatedPermissionList = allPermission.filter(item => (item.accessControlEdit === true) ? item.accessControlEdit = true : false);
      if (this.updatedPermissionList.length > 0) {
        this.permissionSet = this.updatedPermissionList;
        this.permissionSet[0].accessControlEdit = true;
        this.updatedPermissionList = allPermission.filter(item => (item.accessControlView === true) ? item.accessControlView = true : false);
      }
      else {
        this.updatedPermissionList = allPermission.filter(item => (item.accessControlView === true) ? item.accessControlView = true : false);
        this.permissionSet = this.updatedPermissionList;
      }
      if (this.updatedPermissionList.length > 0) {
        this.permissionSet[0].accessControlView = true;
        this.updatedPermissionList = allPermission.filter(item => (item.accessControlCreate === true) ? item.accessControlCreate = true : false);
      }
      else {
        this.updatedPermissionList = allPermission.filter(item => (item.accessControlCreate === true) ? item.accessControlCreate = true : false);
        this.permissionSet = this.updatedPermissionList;
      }
      if (this.updatedPermissionList.length > 0) {
        this.permissionSet[0].accessControlCreate = true;
        this.updatedPermissionList = allPermission.filter(item => (item.accessControlDelete === true) ? item.accessControlDelete = true : false);
      }
      else {
        this.updatedPermissionList = allPermission.filter(item => (item.accessControlDelete === true) ? item.accessControlDelete = true : false);
        this.permissionSet = this.updatedPermissionList;
      }
      if (this.updatedPermissionList.length > 0) {
        this.permissionSet[0].accessControlDelete = true;
      }
      if (this.permissionSet.length > 0) {
        this.permissionSet.forEach((allPermission) => {
          if (allPermission.roleId === Roles.PRTL_USR || allPermission.roleId === Roles.TECH_RCRS) {
            if (allPermission.moduleId === Modules.CASE_MNGT) {
              

              this.permissionList = this.permissionSet[0];
            }
          }
        });
      }
    }
    this.commentsForm = this.formBuilder.group({
      message: [null, [Validators.required]],
    })
    this.commentsDrawerss(this.data.id);
  }

   //comment Form Controller
   get commentsFormCtrl() {
    return this.commentsForm.controls;
  }
  //Patch Value In Dialog Box 
  commentsDrawerss(id) {
    // this.commentsDrawer.open();
    //this.editCommentFlag = true;
    let url = "Comment/GetCommentByCommentId?commentId=";
    this._commonService.get(this._commonService.ReduxAPIURL + url + id).subscribe(
      (resp) => {
        if (resp.isSuccessful === true) {
          this.commentsForm.patchValue(resp.data);
        }
      })
  }

  //Update Comment by comment id
  editComment(id) {
    let url = "Comment/UpdateComment";
    let obj = {
      "commentId": id,
      "message": this.commentsForm.value.message
    }
    this._commonService.post(this._commonService.ReduxAPIURL + url, obj).subscribe(
      (resp) => {
        if (resp.isSuccessful === true) {
          //this.editCommentFlag = false;
          this.toastrService.success(resp.messageDetail.message, 'Success!', {

            positionClass: 'toast-top-full-width',
            closeButton: true,
            timeOut: 7000,

          });
          this.closeFilterDialog();
          this.cd.detectChanges();
        }
      }, (error) => {
        console.log(error);
      });
  }


  closeFilterDialog() {
    this.matDialogRef?.close(this.commentsForm.value);
  }

}

