import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject, takeUntil } from 'rxjs';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { CommonService } from 'app/service/common.service';
import { Router } from '@angular/router';
import { StaticResourcesService } from 'app/service/static-resources.service';

@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'notifications'
})
export class NotificationsComponent implements OnInit, OnDestroy {
    @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
    @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;

    notifications: any;
    unreadCount: number = 0;
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    notificationList: any;
    userDetail: any;
    isCabRole: any;
    isTrRole: any;
    fileCreateList: any;
    fileUploadList: any;
    assessmentCreateList: any;
    teamMemberAssignList: any;
    roleList: any;
    switchAccount: any;
    categoryPermissionList: any;
    additionalFileUpload: any;
    confirmAvailability: any;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _notificationsService: NotificationsService,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private _commonService: CommonService,
        private _router: Router,
        private _cd: ChangeDetectorRef,
        private _staticService: StaticResourcesService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.userDetail = JSON.parse(localStorage.getItem("userProfile"));
        this.switchAccount = JSON.parse(localStorage.getItem("switchAccount"));
        this.roleList = this._staticService.roleList;
        this.categoryPermissionList = this._staticService.categoryPermissionList
        this.isCabRole = this.userDetail?.roleDto.find((x: any) => x.id === this.roleList?.CAB);
        this.isTrRole = this.userDetail?.roleDto.find((x: any) => x.id === this.roleList?.Technical_Resource);
        // Subscribe to notification changes
        // this._notificationsService.notifications$
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((notifications: Notification[]) => {

        //         // Load the notifications
        //         // this.notifications = notifications;

        //         // Calculate the unread count
        //         // this._calculateUnreadCount();

        //         // Mark for check
        //         // this._changeDetectorRef.markForCheck();
        //     });
        this.GetNotification();
    }

    GetNotification() {
        let url: any;
        if (this.isTrRole) {
            if (this.userDetail.assessors && this.userDetail.assessors.technicalResourceId) {
                url = "Notification/GetNotifications?trId=" + this.userDetail.assessors.technicalResourceId
            }
        }
        else if (this.isCabRole) {
            if (this.switchAccount && this.switchAccount.jasanz_agencyid) {
                url = "Notification/GetNotifications?cabId=" + this.switchAccount.jasanz_agencyid
            }
        }
        this._commonService.getResult(this._commonService.ReduxAPIURL + url, "")
            .subscribe((res) => {
                if (res.isSuccessful) {

                    this.fileCreateList = res.data.fileCreate;
                    this.fileUploadList = res.data.fileUpload;
                    this.additionalFileUpload = res.data.additionalFileUpload;
                    this.assessmentCreateList = res.data.assessmentCreate.assessmentlst;
                    this.teamMemberAssignList = res.data.teamMemberAssign.assessmentlst;
                    this.confirmAvailability = res.data.confirmAvailability;
                    this.unreadCount = res.data.count;

                    this.fileCreateList?.map(elem => {
                        elem.msgType = "fileCreate"
                    })
                    this.fileUploadList?.map(elem => {
                        elem.msgType = "fileUpload"
                    })
                    this.assessmentCreateList?.map(elem => {
                        elem.msgType = "assessmentCreate"
                    })
                    this.teamMemberAssignList?.map(elem => {
                        elem.msgType = "assessmentAssign"
                    })
                    this.additionalFileUpload?.map(elem => {
                        elem.msgType = "additionalFileRequest"
                    })
                    this.confirmAvailability?.map(elem => {
                        elem.msgType = "confirmAvailability"
                    })

                    this.notifications = [...this.fileCreateList, ...this.fileUploadList, ...this.additionalFileUpload, ...this.assessmentCreateList, ...this.teamMemberAssignList, ...this.confirmAvailability]
                    this.notifications = this.notifications.sort((a, b) => {
                        return <any>new Date(b.createdOn) - <any>new Date(a.createdOn);
                    });
                    this._cd.detectChanges();
                }
            })

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the notifications panel
     */
    openPanel(): void {
        // Return if the notifications panel or its origin is not defined
        if (!this._notificationsPanel || !this._notificationsOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._notificationsPanel, this._viewContainerRef));
    }

    /**
     * Close the notifications panel
     */
    closePanel(): void {
        this._overlayRef.detach();
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): void {
        // Mark all as read
        this._notificationsService.markAllAsRead().subscribe();
    }

    /**
     * Toggle read status of the given notification
     */
    toggleRead(notification: Notification): void {
        // Toggle the read status
        notification.read = !notification.read;

        // Update the notification
        this._notificationsService.update(notification.id, notification).subscribe();
    }

    /**
     * Delete the given notification
     */
    delete(notification: Notification): void {
        // Delete the notification
        this._notificationsService.delete(notification.id).subscribe();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top'
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom'
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top'
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom'
                    }
                ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    /**
     * Calculate the unread count
     *
     * @private
     */
    private _calculateUnreadCount(): void {
        let count = 0;

        if (this.notifications && this.notifications.length) {
            count = this.notifications.filter(notification => !notification.read).length;
        }

        // this.unreadCount = count;
        this.unreadCount = 0;
    }

    navigateToFile(notification) {
        let assessmentId = '';
        if (notification.msgType === "fileUpload" || notification.msgType === "fileCreate" || notification.msgType === "additionalFileRequest") {
            assessmentId = notification.assessmentId;
        }
        if (notification.msgType === "assessmentCreate" || notification.msgType === "assessmentAssign" || notification.msgType === "confirmAvailability") {
            assessmentId = notification.id;
        }
        this._staticService.getAllRoleList();
        this._staticService.getAllModuleList();
        this._staticService.getAllCategoryList();
        this._staticService.getCategoryPermissionSet();
        let url = null;
        if (this.isTrRole) {
            if (this.userDetail.assessors && this.userDetail.assessors.technicalResourceId) {
                url = "Assessment/GetAssessmentList?trId=" + this.userDetail.assessors.technicalResourceId
            }
        }
        else if (this.isCabRole) {
            if (this.switchAccount && this.switchAccount.jasanz_agencyid) {
                url = "Assessment/GetAssessmentList?cabId=" + this.switchAccount.jasanz_agencyid
            }
        }
        this._commonService.getResult(this._commonService.ReduxAPIUR + url, "")
            .subscribe((res: any) => {

                let assesmentList = res.data;
                let atmList = assesmentList.find((e: any) => e.id === assessmentId);
                // this._commonService.atmList = atmList.category;
                if (this.isTrRole) {
                    this._commonService.loggedInAtm = atmList?.category;
                    let categoryFound = [];
                    atmList?.category?.forEach((elem) => {
                        this.categoryPermissionList.allTeamMember?.forEach((elemen) => {
                            if (elem.id === elemen) {
                                categoryFound.push(elemen)
                            }
                        })
                    })
                    if (categoryFound?.length > 0) {
                        let url = 'Assessment/GetAssessmentTeamMemberListById?Id=' + atmList.id;
                        this._commonService.getResult(this._commonService.ReduxAPIURL + url, "")
                            .subscribe((res) => {
                                let allCategoryList = [];
                                res.data?.forEach((e) => {
                                    let abc = e?.name?.split('- ')
                                    let atmName = abc?.slice(1).join('- ');
                                    let obj = {
                                        id: e.categoryId,
                                        name: e.categoryName,
                                        atmId: e.id,
                                        atmName: atmName,
                                        assessmentId: e.assessmentId,
                                        resultId: e.resultId
                                    }
                                    allCategoryList.push(obj);
                                })
                                this._commonService.atmList = allCategoryList;
                            })
                    }
                    else {
                        atmList?.category?.forEach((e) => {
                            let abc = e?.atmName?.split('- ')
                            let atmName = abc?.slice(1).join('- ');
                            e.atmName = atmName
                        })
                        this._commonService.atmList = atmList.category;
                    }
                } else if (this.isCabRole) {
                    let url = 'Assessment/GetAssessmentTeamMemberListById?Id=' + atmList.id;
                    this._commonService.getResult(this._commonService.ReduxAPIURL + url, "")
                        .subscribe((res) => {
                            let allCategoryList = [];
                            res.data?.forEach((e) => {
                                let abc = e?.name?.split('- ')
                                let atmName = abc?.slice(1).join('- ');
                                let obj = {
                                    id: e.categoryId,
                                    name: e.categoryName,
                                    atmId: e.id,
                                    atmName: atmName,
                                    assessmentId: e.assessmentId,
                                    resultId: e.resultId
                                }
                                allCategoryList.push(obj);
                            })
                            this._commonService.atmList = allCategoryList;
                        })
                }

                if (notification.msgType === "fileUpload" || notification.msgType === "fileCreate" || notification.msgType === "additionalFileRequest") {
                    assessmentId = notification.assessmentId;
                    this._router.navigate(['/assessments/file/my-assessments'], { queryParams: { assessmentId: notification.assessmentId } });
                } else if (notification.msgType === "assessmentCreate" || notification.msgType === "assessmentAssign" || notification.msgType === "confirmAvailability") {
                    assessmentId = notification.id;
                    this._router.navigate(['/assessments/scope/assessment-scope/summary'], { queryParams: { assessmentId: notification.id } });
                }
            })
    }
}