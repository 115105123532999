// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//ng build --configuration=production --output-hashing none

export const environment = {
    production: false,
  //Local URL
  //  api:"https://localhost:44377/api/V1/"
  //"webURL": "http://localhost:4200/"
  //CUPR URL
  devAuthApi: "https://reduxauthservice-dev.jas-anz.org/api/V1/",
  devReduxAPI: "https://reduxapi-dev.jas-anz.org/api/V1/",
  // "devReportingServiceAPI": "https://reportingapi-dev.jas-anz.org/api/V1/",
  // "devCertificateServiceAPI": "https://managecertificateapi-dev.jas-anz.org/api/V1/",
  // "devFileUploadAPI": "https://fileuploadapi-dev.jas-anz.org/api/V1/",
   // "devAzureBlobURL":"https://jasanzcuprdev.blob.core.windows.net/certificatedatafiles/",
  // "prodAuthApi": "https://authapi-dev.jas-anz.org/api/V1/",
  // "prodReportingServiceAPI": "https://reportingapi-dev.jas-anz.org/api/V1/",
  // "prodCertificateServiceAPI": "https://managecertificateapi-dev.jas-anz.org/api/V1/",
  // "prodFileUploadAPI": "https://fileuploadapi-dev.jas-anz.org/api/V1/",
  // "prodAzureBlobURL":"https://jasanzcuprdev.blob.core.windows.net/certificatedatafiles/",
  // "webURL": "https://webapp-dev.jas-anz.org/",
  
 
  
  //PROD URL
    // "devAuthApi": "https://authapi.jas-anz.org/api/V1/",
    // "devReportingServiceAPI": "https://reportingapi.jas-anz.org/api/V1/",
    // "devCertificateServiceAPI": "https://managecertificateapi.jas-anz.org/api/V1/",
    // "devFileUploadAPI": "https://fileuploadapi.jas-anz.org/api/V1/",
     //"devAzureBlobURL":"https://jasanz.blob.core.windows.net/certificatedatafiles/",
    // "prodAuthApi": "https://authapi.jas-anz.org/api/V1/",
    // "prodReportingServiceAPI": "https://reportingapi.jas-anz.org/api/V1/",
    // "prodCertificateServiceAPI": "https://managecertificateapi.jas-anz.org/api/V1/",
    // "prodFileUploadAPI": "https://fileuploadapi.jas-anz.org/api/V1/",
    //"prodAzureBlobURL":"https://jasanz.blob.core.windows.net/certificatedatafiles/",
    // "webURL":"https://portal.jas-anz.org/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
