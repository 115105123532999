import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StaticResourcesService {
  roleList: any;
  moduleList: any;
  public rolePermission: Subject<boolean> = new Subject();
  masterData = new BehaviorSubject<any>(null);
  schemeList = new BehaviorSubject<any>(null);
  recomSchemeList = new BehaviorSubject<any>(null);
  requirementList = new BehaviorSubject<any>(null);
  assessedCriteria = new BehaviorSubject<any>(null);
  auditorList = new BehaviorSubject<any>(null);
  // subscription: Subscription = new Subscription();
  categoryList: any;
  assessmentDetail: any;
  categoryPermissionList: any;

  constructor(
    private commonService: CommonService
  ) { }

  getAllRoleList() {

    let url = 'Auth/GetAllRoleList'
    this.commonService.getAllRolesModules(url)
      .subscribe((res: any) => {
        this.roleList = res.data;
        // this.rolePermission.next(true);
      });
  }
  getAllModuleList() {
    let url = 'Auth/GetAllModuleList'
    this.commonService.getAllRolesModules(url)
      .subscribe((res: any) => {
        this.moduleList = res.data;
        // this.rolePermission.next(true);
      });
  }
  getAllCategoryList() {
    let url = 'Auth/GetAllCategoryList'
    this.commonService.getAllRolesModules(url)
      .subscribe((res: any) => {
        this.categoryList = res.data;
        this.rolePermission.next(true);
      });
  }

  getMasterData() {
    return this.commonService.getResult(this.commonService.ReduxAPIUR + "Assessment/GetMasterData?input=a", "")
  }

  getCategoryPermissionSet() {
    return this.commonService.loadJSONFileData('./assets/config/category-permission.json')
  }
}
