import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable, take } from "rxjs";
import { NavigationService } from "app/core/navigation/navigation.service";
import { Navigation } from "app/core/navigation/navigation.types";
import { AsyncSubject } from 'rxjs';
import { environment } from "../../environments/environment";

export interface SelectedFiles {
    name: string;
    fileType: any;
    base64?: string;

}

@Injectable({
    providedIn: 'root'
})
export class CommonService {


    SharingTeamData = new BehaviorSubject<any>(0);
    sharedCabId = new BehaviorSubject<any>(null);
    assessmentDetails = new BehaviorSubject<any>(null);
    decisionDetail = new BehaviorSubject<any>(null);
    decisionAssessmentId = new BehaviorSubject<any>(null);
    adpDetails = new BehaviorSubject<any>(null);

    atmList = null;
    loggedInAtm = null;
    public jasanzURL: any;
    public AuthAPIURL: any;
    public ReduxAPIURL: any;
    public RegisterAPIURL: any;
    public CuprAPIURL: any;
    public RegisterURL: any;

    public name: any;
    //Store Value
    audit!: any;
    auditsId = new BehaviorSubject<any>(this.audit);
    auditResult = this.auditsId.asObservable();
    private isActive = new BehaviorSubject<any>(null);
    latestIsActive = this.isActive.asObservable();


    cert!: any;
    certificateId = new BehaviorSubject<any>(this.cert);
    certificateIdResult = this.certificateId.asObservable();
    navigation: Navigation;
    drawerOpened: any;
    public AuthAPIUR: any;
    public ReduxAPIUR: any;


    constructor(private httpClient: HttpClient, private _navigationService: NavigationService) {
        this._navigationService.navigation$
            .pipe(take(1))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
                localStorage.setItem('navigation', JSON.stringify(this.navigation));
            });

        this.AuthAPIUR = environment.devAuthApi;
        this.ReduxAPIUR = environment.devReduxAPI;
    }
    public headers = new HttpHeaders()
        .set('content-type', 'application/json')
        .set('Access-Control-Allow-Origin', '*');


    updateCertificateId(cert: any) {
        this.certificateId.next(cert)
    }

    updateIsActive(flag: any) {
        this.isActive.next(flag);
    }

    updateAuditId(aud: any) {
        this.auditsId.next(aud)
    }


    //Get All Result
    getAllDataTableResult(method: string, dtParams: any): Observable<any> {
        return this.httpClient.post(method, dtParams);
    }

    //Insert And update operation
    insertUpdateDetail(method: string, body: any) {
        const headers = new HttpHeaders().set("content-type", "application/json");
        return this.httpClient.post(method, body, {
            headers,
            responseType: "text",
        });
    }

    //Insert And update operation with response type object 
    insertUpdateDetailWithJsonResp(method: string, body: any): Observable<any> {
        const headers = new HttpHeaders().set("content-type", "application/json");
        return this.httpClient.post(method, body, {
            headers,
            responseType: "json",
        });
    }

    // Delete Operation
    deleteDetail(method: string, body: any) {
        const headers = new HttpHeaders().set("content-type", "application/json");
        return this.httpClient.post(method, body, {
            headers,
            responseType: "json",
        });
    }

    //Get List or Object
    getResult(method: string, dtParams: any): Observable<any> {
        return this.httpClient.get(method, dtParams);
    }

    getFilter(params: string) {
        return this.httpClient.get(this.CuprAPIURL + params);
    }

    //Insert And update operation with json response
    post(method: string, body: any): Observable<any> {
        const headers = new HttpHeaders().set("content-type", "application/json");
        return this.httpClient.post(method, body, {
            headers,
            responseType: "json",
        });
    }

    postFile(method: any, body: any): Observable<any> {
        return this.httpClient.post<any>(method, body);
    }

    fetch(method, auth: any): Observable<any> {
        const headers = new HttpHeaders().set("Authorization", auth);
        return this.httpClient.get(method, {
            headers,
            responseType: "json",
        });
    }
    //get data 
    get(method: string,): Observable<any> {
        return this.httpClient.get(method);
    }
    // Delete
    delete(method: string,): Observable<any> {
        return this.httpClient.delete(method);
    }
    //Put
    put(method: string, body: any): Observable<any> {
        const headers = new HttpHeaders().set("content-type", "application/json");
        return this.httpClient.put(method, body, {
            headers,
            responseType: "json",
        });
    }
    //get data from json file
    loadJSONFileData(jsonPath): Observable<any> {
        return this.httpClient.get(jsonPath);
    }

    public toBase64(files: File[], selectedFiles: SelectedFiles[]): Observable<SelectedFiles[]> {
        const result = new AsyncSubject<SelectedFiles[]>();
        if (files?.length) {
            Object.keys(files)?.forEach((file, i) => {
                const url = URL.createObjectURL(files[i]);
                const reader = new FileReader();
                reader.readAsDataURL(files[i]);
                reader.onload = (e) => {
                    selectedFiles = selectedFiles?.filter(f => f?.name != files[i]?.name);
                    selectedFiles.push({ name: files[i]?.name, fileType: files[i], base64: reader?.result as string });
                    result.next(selectedFiles);
                    //if(files?.length === (i+1))
                    if (files?.length === (selectedFiles.length)) {
                        result.complete();
                    }
                };
            });
            return result;
        } else {
            result.next([]);
            result.complete();
            return result;
        }
    }

    // HidetabFilter(filterString: any) {
    //     this.hideTabString.next(filterString);
    // }
    getAllRolesModules(url) {
        return this.httpClient.get(this.AuthAPIUR + url);
    }
}
