import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { Subject, takeUntil } from 'rxjs';
import { User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from 'app/core/auth/auth.service';
import { CommonService } from 'app/service/common.service';
import { Roles } from 'app/models/master-role';
import { StaticResourcesService } from 'app/service/static-resources.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'user'
})
export class UserComponent implements OnInit, OnDestroy {
  /* eslint-disable @typescript-eslint/naming-convention */
  static ngAcceptInputType_showAvatar: BooleanInput;
  /* eslint-enable @typescript-eslint/naming-convention */
  azureUserProfile: any;
  @Input() showAvatar: boolean = true;
  user: User;
  logo: any;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  userDetail: any;
  switchAccount: any;
  isCabRole: any;
  isTrRole: any;
  showUnavailability: boolean = false;
  showScope: boolean = false;
  showCabsite: boolean = false;
  roleList: any;
  Permission = Permissions;
  Role = Roles;
  cabSitesList: any;
  listOfFiles: any = [];
  filesLengthFlag: boolean;
  fileList: any = [];
  fileLength: number;
  selectedFiles: any[];
  showFileSizeContact: any;
  outcomeOutput: boolean = true;

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private _userService: UserService,
    private _msalService: MsalService,
    private _authService: AuthService,
    private _commonService: CommonService,
    private _staticService: StaticResourcesService,
    private _toastrService: ToastrService
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.userDetail = JSON.parse(localStorage.getItem("userProfile"));
    this.switchAccount = JSON.parse(localStorage.getItem("switchAccount"));
    this.roleList = this._staticService.roleList;
    this.isCabRole = this.userDetail.roleDto.find((x: any) => x?.id === Roles.CAB)?.id
    this.isTrRole = this.userDetail.roleDto.find((x: any) => x?.id === Roles.TECH_RCRS)?.id

    let path = window.location.pathname;
    if(path.includes('outcomes-output')|| path.includes('/decision-output')){
      this.outcomeOutput = false;
    } else {
      this.outcomeOutput = true;
    }

    if (this.isCabRole) {
      // if (this.switchAccount?.logo != null) {
      //   this.logo = this.switchAccount.logo;
      // }
      // else {
      //   this.logo = "assets/images/logo/userlogo.png";
      // }
      if (this.switchAccount && this.switchAccount.jasanz_agencyid) {
        this.showUnavailability = true;
        this.showCabsite = true;
        this.GetSiteList()
      }
      else {
        this.showUnavailability = false;
        this.showCabsite = false;
      }
    }
    else if (this.isTrRole) {
      if (this.userDetail.assessors && this.userDetail.assessors.technicalResourceId) {
        this.showUnavailability = true;
        this.showScope = true;
      }
      else {
        this.showUnavailability = false;
        this.showScope = false;
      }
    }

    if (this.userDetail.logo != null) {
      this.logo = this.userDetail.logo;
    }
    else {
      this.logo = "assets/images/logo/userlogo.png";
    }

    this.getClaim();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Update the user status
   *
   * @param status
   */

  getClaim() {
    this.azureUserProfile = JSON.parse(this._authService.azureUserProfile);
  }

  myProfile(): void{
    this._router.navigate(['/my-profile/about-me'])
  }

  signOut(): void {
    this._router.navigate(['/sign-out']);
  }

  myUnavailability(): void {
    this._router.navigate(['/profile/my-unavailability']);
  }

  myScope(): void {
    this._router.navigate(['/profile/my-scope']);
  }

  mySites(): void {
    this._router.navigate(['/profile/sites/site'], { queryParams: { siteId: this.cabSitesList.id } });
  }

  GetSiteList() {
    let url = "Assessment/GetSiteDetailsByCabId?cabId=" + this.switchAccount.jasanz_agencyid;
    this._commonService.getResult(this._commonService.ReduxAPIURL + url, "")
      .subscribe((res) => {
        this.cabSitesList = res?.data[0];
      })
  }


  onSelectContactAttachments(files: any) {
    //this.filesLengthFlag = false;

    if (this.listOfFiles?.length <= 1 && this.fileList.length <= 1 && files.length <= 1 && files.length + this.listOfFiles.length <= 1) {
      this.filesLengthFlag = false;
      if (files.length == 0) {
        this.fileList = [];
        this.listOfFiles = [];
      }

      this.fileLength = 0;
      this.selectedFiles = [];
      this._commonService.toBase64(files, this.selectedFiles).subscribe((res) => {
        if (res) {
          if (res.length > 0) {

            this.fileLength = res.length;
            res.forEach((ele) => {

              let file: any
              file = {
                name: ele.name,
                base64: ele.base64?.split("base64,")[1],
                filetype: ele.fileType.type
              };
              let showfile = {
                name: ele.name,
                size: ele.fileType.size,
                errorFlag: false,
              }
              this.returnFileSizeContact(ele.fileType.size, showfile);
              // this.fileList.push(file);
              this.listOfFiles.push(showfile);
              //   this.dataSources = new MatTableDataSource(this.listOfFiles);
              this.showFileSizeContact = this.listOfFiles.find(item => item.errorFlag === true)


              let obj = {
                id: this.userDetail.contact_id,
                user_profile: file.base64
              }

              let url = "Auth/UpdateUserProfile"
              this._commonService.post(this._commonService.AuthAPIURL + url, obj)
                .subscribe((res) => {
                  if (res) {
                    let userProfile: any = {
                      accessControlsDto: this.userDetail.accessControlsDto,
                      assessors: this.userDetail.assessors,
                      cabsDtos: this.userDetail.cabsDtos,
                      contactType: this.userDetail.contactType,
                      contact_id: this.userDetail.contact_id,
                      email: this.userDetail.email,
                      flag: this.userDetail.flag,
                      fullname: this.userDetail.fullname,
                      isJasanzUser: this.userDetail.isJasanzUser,
                      jasanz_agencyid: this.userDetail.jasanz_agencyid,
                      jasanz_agencytype: this.userDetail.jasanz_agencytype,
                      logo: "data:image/png;base64," + file.base64,
                      roleDto: this.userDetail.roleDto,
                      schemesDto: this.userDetail.schemesDto,
                      setPortalAccess: this.userDetail.setPortalAccess
                    }
                    // if (this.isTrRole) {
                    localStorage.setItem('userProfile', JSON.stringify(userProfile))
                    // }
                    // else if (this.isCabRole) {
                    //   let switchAccount: any = {
                    //     abbreviation: this.switchAccount.abbreviation,
                    //     cab_name: this.switchAccount.cab_name,
                    //     certificatefileuploadmethod: this.switchAccount.certificatefileuploadmethod,
                    //     jasanz_agencyid: this.switchAccount.jasanz_agencyid,
                    //     jasanz_agencytype: this.switchAccount.jasanz_agencytype,
                    //     logo: "data:image/png;base64," + file.base64,
                    //     requiresextrascrutiny: this.switchAccount.requiresextrascrutiny
                    //   }
                    //   let abc = this.userDetail.cabsDtos.find((x) => x.id === this.switchAccount.jasanz_agencyid)
                    //   abc.logo = "data:image/png;base64," + file.base64;
                    //   localStorage.setItem('userProfile', JSON.stringify(userProfile))
                    //   localStorage.setItem('switchAccount', JSON.stringify(switchAccount))
                    // }
                    this.logo = "data:image/png;base64," + file.base64
                    this._toastrService.success("Profile update Successfull");
                    window.location.reload();
                  }
                })
            })
          }
        }
      });
    }
    else {
      this.filesLengthFlag = true;
    }
  }

  //get maximum 5MB File 
  returnFileSizeContact(number, obj) {
    var num = (number / 1048576);//mb
    if (num > 10) {
      obj.errorFlag = true
      this._toastrService.error("A maximum size of 10mb document is supported.")
      // this.showFileSizeContact = true;
    }
    else { obj.errorFlag = false }
  }

}
