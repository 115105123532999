/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';
import $ from "jquery";
export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/dashboards/project',
    },
    {
        id: 'case',
        title: 'My Cases',
        tooltip: 'My Cases',
        type: 'aside',
        icon: 'heroicons_outline:folder-open',
        children: [
            {
                id: 'case.create-case',
                title: 'Create Case',
                type: 'basic',
                icon: 'heroicons_outline:plus',
                link: '/case-management/add-new-case'
            },
            {
                id: 'case.view-case',
                title: 'Review Cases',
                type: 'basic',
                icon: 'heroicons_outline:folder-open',
                link: '/case-management/cases'
            },

        ]
    },
    {
        id: 'application',
        title: 'Application',
        tooltip: 'Application',
        type: 'aside',
        icon: 'heroicons_outline:clipboard-document',
        children: [
            {
                id: 'application.create-application',
                title: 'Create Application',
                type: 'basic',
                icon: 'heroicons_outline:plus',
                link: '/application/create-application/add-new-application'
            },
            {
                id: 'application.review-application',
                title: 'Review Application',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-document',
                link: '/application/review-application'
            },
        ]
    },
    {
        id: 'assessments',
        title: 'Assessments',
        tooltip: 'Assessments',
        type: 'aside',
        icon: 'heroicons_outline:clipboard-document-list',
        children: [
            {
                id: 'assessments.my-assessments',
                title: 'My Assessments',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-document-list',
                link: '/assessment/my-assessment'
            },
        ]
    },
    {
        id: 'adp-decisions',
        title: 'ADP Decisions',
        tooltip: 'ADP Decisions',
        type: 'aside',
        icon: 'heroicons_outline:clipboard-document-check',
        children: [
            {
                id: 'adp-decisions.search-adp-decisions',
                title: 'Search ADP Decisions',
                type: 'basic',
                icon: 'heroicons_outline:document-search',
                link: '/adp-decisions/search-adp-decisions'
            },
            {
                id: 'adp-decisions.review-adp-decisions',
                title: 'Review ADP Decisions',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-document-check',
                link: '/adp-decisions/decision-list'
            },
        ]
    },
    {
        id: 'endorsed-schemes',
        title: 'Schemes',
        tooltip: 'Schemes',
        type: 'aside',
        icon: 'heroicons_outline:puzzle',
        children: [
            {
                id: 'endorsed-schemes.schemes',
                title: 'Schemes',
                type: 'basic',
                icon: 'heroicons_outline:puzzle',
                link: '/endorsed-schemes'
            },
        ]
    },
    {
        id: 'certificate',
        title: 'Certificates',
        tooltip: 'Certificates',
        type: 'aside',
        icon: 'heroicons_outline:document-search',
        children: [
            {
                id: 'certificate.search-certificate',
                title: 'Search Certificates',
                type: 'basic',
                icon: 'heroicons_outline:document-search',
                link: '/manage-certificate/search-certificate'
            },
            {
                id: 'certificate.my-export',
                title: 'My Export',
                type: 'basic',
                icon: 'heroicons_outline:document-download',
                link: '/manage-certificate/my-export'
            },

        ]
    },
    {
        id: 'schedules',
        title: 'Schedules',
        tooltip: 'Schedules',
        type: 'aside',
        icon: 'heroicons_solid:shield-check',
        children: [
            {
                id: 'schedules.acc-schedules',
                title: 'Accreditation Schedules',
                type: 'basic',
                icon: 'heroicons_outline:document-search',
                link: '/schedules/search-accreditation-scheduler',
                // externalLink: true,
                // target: '_blank'
            },
            {
                id: 'schedules.export-schedules',
                title: 'My Exports',
                type: 'basic',
                icon: 'heroicons_outline:document-download',
                link: '/schedules/my-exports',
                // externalLink: true,
                // target: '_blank'
            },
        ]
    },
    {
        id: 'community',
        title: 'Community',
        tooltip: 'Community',
        type: 'aside',
        icon: 'heroicons_solid:user-group',
        children: [
            {
                id: 'community.community',
                title: 'Community',
                type: 'basic',
                icon: 'heroicons_solid:user-group',
                link: 'https://engage.cloud.microsoft/main/feed',
                externalLink: true,
                target: '_blank'
            },
        ]
    },
    {
        id: 'legacy-data',
        title: 'Legacy Data',
        tooltip: 'Legacy Data',
        type: 'aside',
        icon: 'heroicons_solid:user-group',
        children: [
            {
                id: 'legacy-data.working-document',
                title: 'Working Document',
                type: 'basic',
                icon: 'heroicons_solid:user-group',
                link: '/legacy-data/working-document'
            },
            {
                id: 'legacy-data.final-document',
                title: 'Final Document',
                type: 'basic',
                icon: 'heroicons_outline:document-search',
                link: '/legacy-data/final-document'
            }
            // {
            //     id: 'legacy-data.read-and-download-access',
            //     title: 'Read and Download Access',
            //     type: 'basic',
            //     icon: 'heroicons_outline:document-download',
            //     link: '/legacy-data/read-and-download-access'
            // },
        ]
    },
    {
        id: 'invoice',
        title: 'Invoice',
        tooltip: 'Invoice',
        type: 'aside',
        icon: 'heroicons_solid:currency-dollar',
        children: [
            {
                id: 'invoice.submit-invoice',
                title: 'Submit Invoice',
                type: 'basic',
                icon: 'heroicons_outline:mail',
                link:''
            },
            {
                id: 'invoice.review-invoice',
                title: 'Review Invoice',
                type: 'basic',
                icon: 'heroicons_outline:mail',
                link: '/invoice/review-invoice'
            },
        ]
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/dashboards/project',
    },
    {
        id: 'case',
        title: 'My Cases',
        tooltip: 'My Cases',
        type: 'aside',
        icon: 'heroicons_outline:folder-open',
        children: [
            {
                id: 'case.create-case',
                title: 'Create Case',
                type: 'basic',
                icon: 'heroicons_outline:plus',
                link: '/case-management/add-new-case'
            },
            {
                id: 'case.view-case',
                title: 'Review Cases',
                type: 'basic',
                icon: 'heroicons_outline:document-report',
                link: '/case-management/cases'
            },

        ]
    },
    {
        id: 'application',
        title: 'Application',
        tooltip: 'Application',
        type: 'aside',
        icon: 'heroicons_outline:clipboard-document',
        children: [
            {
                id: 'application.create-application',
                title: 'Create Application',
                type: 'basic',
                icon: 'heroicons_outline:plus',
                link: '/application/create-application/add-new-application'
            },
            {
                id: 'application.review-application',
                title: 'Review Application',
                type: 'basic',
                icon: 'heroicons_outline:document-report',
                link: '/application/review-application'
            },
        ]
    },
    {
        id: 'assessments',
        title: 'Assessments',
        tooltip: 'Assessments',
        type: 'aside',
        icon: 'heroicons_outline:clipboard-document-list',
        children: [
            {
                id: 'assessments.my-assessments',
                title: 'My Assessments',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-document-list',
                link: '/assessment/my-assessment'
            },
        ]
    },
    {
        id: 'adp-decisions',
        title: 'ADP Decisions',
        tooltip: 'ADP Decisions',
        type: 'aside',
        icon: 'heroicons_outline:clipboard-document-check',
        children: [
            {
                id: 'adp-decisions.search-adp-decisions',
                title: 'Search ADP Decisions',
                type: 'basic',
                icon: 'heroicons_outline:document-search',
                link: '/adp-decisions/search-adp-decisions'
            },
            {
                id: 'adp-decisions.review-adp-decisions',
                title: 'Review ADP Decisions',
                type: 'basic',
                icon: 'heroicons_outline:document-report',
                link: '/adp-decisions/decision-list'
            },
        ]
    },
    {
        id: 'endorsed-schemes',
        title: 'Schemes',
        tooltip: 'Schemes',
        type: 'aside',
        icon: 'heroicons_outline:puzzle',
        children: [
            {
                id: 'endorsed-schemes.schemes',
                title: 'Schemes',
                type: 'basic',
                icon: 'heroicons_outline:puzzle',
                link: '/endorsed-schemes'
            },
        ]
    },
    {
        id: 'certificate',
        title: 'Certificates',
        tooltip: 'Certificates',
        type: 'aside',
        icon: 'heroicons_outline:document-search',
        children: [
            {
                id: 'certificate.search-certificate',
                title: 'Search Certificates',
                type: 'basic',
                icon: 'heroicons_outline:document-search',
                link: '/manage-certificate/search-certificate'
            },
            {
                id: 'certificate.my-export',
                title: 'My Export',
                type: 'basic',
                icon: 'heroicons_outline:document-download',
                link: '/manage-certificate/my-export'
            },

        ]
    },
    {
        id: 'schedules',
        title: 'Schedules',
        tooltip: 'Schedules',
        type: 'aside',
        icon: 'heroicons_solid:shield-check',
        children: [
            {
                id: 'schedules.acc-schedules',
                title: 'Accreditation Schedules',
                type: 'basic',
                icon: 'heroicons_outline:document-search',
                link: '/schedules/search-accreditation-scheduler',
                // externalLink: true,
                // target: '_blank'
            },
            {
                id: 'schedules.export-schedules',
                title: 'My Exports',
                type: 'basic',
                icon: 'heroicons_outline:document-download',
                link: '/schedules/my-exports',
                // externalLink: true,
                // target: '_blank'
            },
        ]
    },
    {
        id: 'community',
        title: 'Community',
        tooltip: 'Community',
        type: 'aside',
        icon: 'heroicons_solid:user-group',
        children: [
            {
                id: 'community.community',
                title: 'Community',
                type: 'basic',
                icon: 'heroicons_solid:user-group',
                link: 'https://engage.cloud.microsoft/main/feed',
                externalLink: true,
                target: '_blank'
            },
        ]
    },
    {
        id: 'legacy-data',
        title: 'Legacy Data',
        tooltip: 'Legacy Data',
        type: 'aside',
        icon: 'heroicons_solid:user-group',
        children: [
            {
                id: 'legacy-data.working-document',
                title: 'Working Document',
                type: 'basic',
                icon: 'heroicons_solid:user-group',
                link: '',
                externalLink: true,
                target: '_blank'
            },
            {
                id: 'legacy-data.final-document',
                title: 'Final Document',
                type: 'basic',
                icon: 'heroicons_outline:document-search',
                link: ''
            },
            // {
            //     id: 'legacy-data.read-and-download-access',
            //     title: 'Read and Download Access',
            //     type: 'basic',
            //     icon: 'heroicons_outline:document-download',
            //     link: ''
            // },
        ]
    },
    {
        id: 'invoice',
        title: 'Invoice',
        tooltip: 'Invoice',
        type: 'aside',
        icon: 'heroicons_solid:currency-dollar',
        children: [
            {
                id: 'invoice.submit-invoice',
                title: 'Submit Invoice',
                type: 'basic',
                icon: 'heroicons_outline:mail'
            },
            {
                id: 'invoice.review-invoice',
                title: 'Review Invoice',
                type: 'basic',
                icon: 'heroicons_outline:mail',
                link: '/invoice/review-invoice'
            },
        ]
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'Dashboard',
        //subtitle: 'Unique dashboard designs',
        type: 'collapsable',
        icon: 'heroicons_outline:home',
        link: '/dashboards/project',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },

];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'Dashboard',
        //subtitle: 'Unique dashboard designs',
        type: 'collapsable',
        icon: 'heroicons_outline:home',
        link: '/dashboards/project',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },

    {
        id: 'support',
        title: 'Support',
        //subtitle: 'Unique dashboard designs',
        tooltip: 'Support',
        type: 'aside',
        icon: 'heroicons_outline:support',
        children: []
    }

];
function issueCollectorCall(): (item: FuseNavigationItem) => void {

    $.ajax({
        url: "https://tasks.jas-anz.org/plugins/servlet/issueCollectorBootstrap.js?collectorId=b1224284&locale=en_GB",
        type: "get",
        cache: true,
        dataType: "script"
    });
    return
};

