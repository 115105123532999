<div class="flex flex-col max-w-580 md:min-w-160 -m-6">



    <!-- Header -->

    <div class="flex flex-0 items-center justify-between h-16 px-4 bg-primary text-on-primary">

        <div class="text-lg">{{ 'matstepper.editComment'| translate }}</div>

        <button mat-icon-button type="button" (click)="closeFilterDialog()" mat-dialog-close [tabIndex]="-1">

            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>

        </button>

    </div>



    <form class="flex flex-col flex-auto overflow-y-auto" [formGroup]="commentsForm">

        <div class="w-full xl:w-1/1 lg:w-1/1 md:w-1/1 px-4 py-4">

            <mat-form-field class="w-full fuse-mat-dense">

                <mat-label>{{ 'case.comment' | translate }}</mat-label>

                <textarea [formControlName]="'message'" maxlength="2500" placeholder="No more than 2500 characters."
                    class="min-h-5 my-0" style="margin: 11px 0 !important; padding: 0 !important;" [rows]="4" matInput
                    #message></textarea>

                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:chat'">

                </mat-icon>

                <mat-error
                    *ngIf="commentsFormCtrl.message.touched && commentsFormCtrl.message.invalid || commentsFormCtrl.message.errors  && submitted">
                    <small *ngIf="commentsFormCtrl.message.errors.required ">
                        {{'matstepper.commentFieldValidation' | translate }}
                    </small>
                </mat-error>
               
            </mat-form-field>
            <span *ngIf="commentsFormCtrl.message.value">{{ 2500 -
                commentsFormCtrl.message.value.length }} characters
                remaining</span>
        </div>



        <mat-divider></mat-divider>



        <!-- Actions -->

        <div class="flex flex-col sm:flex-row sm:items-center justify-end p-4">

            <div class="flex items-center mt-4 sm:mt-0">

                <!-- Discard -->

                <button id="btn_cancel" aria-label="Cancel" title="Cancel" mat-dialog-close type="button"
                     (click)="closeFilterDialog()" mat-button>
                    {{ 'case.cancel' | translate }}

                </button>



                <!-- Save -->

                <button id="btn_save" *ngIf="permissionList.accessControlEdit" aria-label="Save" title="Save" [disabled]="commentsForm.invalid" mat-flat-button type="submit" [color]="'primary'"
                    (click)="editComment(data.id)">
                    {{ 'matstepper.update' | translate }}

                </button>

            </div>

        </div>

    </form>

</div>