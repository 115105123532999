import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class MsalGuard implements CanActivate {
    constructor(
 private _msalService: MsalService,
        private _router: Router,
    ) {
    }
  canActivate(
    route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (this._msalService.instance.getActiveAccount() == null) {
          this._router.navigate(['/support/sign-in'], { queryParams: { returnUrl: state.url } });
          return false;
      }
      else { return true;}
 
  }
  
}
