import { AuthService } from './../../../../core/auth/auth.service';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { FormControl } from '@angular/forms';
import { CommonService } from 'app/service/common.service';
import { Permissions } from 'app/models/master-permission';
import { Roles } from 'app/models/master-role';
import { Modules } from 'app/models/master-module';
import { StaticResourcesService } from 'app/service/static-resources.service';
interface Switch {
    id: string;
    value: string;
}

interface SwitchGroup {
    disabled?: boolean;
    name: string;
    switchaccount: Switch[];
}

@Component({
    selector: 'compact-layout',
    templateUrl: './compact.component.html',
    encapsulation: ViewEncapsulation.None
})
export class CompactLayoutComponent implements OnInit, OnDestroy {
    selected = "help";
    switchControl = new FormControl();
    switchGroups: SwitchGroup[] = null;
    isScreenSmall: boolean;
    Permission = Permissions;
    Role = Roles;
    Module = Modules;
    //data: InitialData;
    navigation: Navigation;
    selectList: any;
    id: string = "";
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    userProfile: any;
    roleList: any;
    moduleList: any;
    jasanzURL: any;
    cabDto: any;
    userDetail: any;
    switchAccounts: any;
    isCabRole: any;
    isTrRole: any;
    outcomeOutput: boolean;

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _commonService: CommonService,
        private _router: Router,
        private _authService: AuthService,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _staticService: StaticResourcesService,
        private _cd: ChangeDetectorRef
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.build();
        this.jasanzURL = this._commonService.jasanzURL;
        this.cabDto = JSON.parse(localStorage.getItem("userProfile"))?.cabsDtos;

        this.roleList = this._staticService.roleList;
        this.moduleList = this._staticService.moduleList;

        this.userDetail = JSON.parse(localStorage.getItem("userProfile"));
        this.switchAccounts = JSON.parse(localStorage.getItem("switchAccount"));

        this.isCabRole = this.userDetail?.roleDto.find((x: any) => x.id === this.roleList?.CAB);
        this.isTrRole = this.userDetail?.roleDto.find((x: any) => x.id === this.roleList?.Technical_Resource);

        let path = window.location.pathname;
        if (path.includes('outcomes-output') || path.includes('/decision-output')) {
            this.outcomeOutput = false;
        } else {
            this.outcomeOutput = true;
        }

        this._cd.detectChanges();
    }


    build() {

        this._activatedRoute.data.subscribe((data: Data) => {
            let permissionMenus = [];
            data.initialData.forEach((element: any) => {
                let menu = [];
                menu = element.default;
                if (localStorage.getItem("userProfile") !== null) {
                    const userDetail = JSON.parse(localStorage.getItem("userProfile"));
                    const accessControlList = JSON.parse(localStorage.getItem("Access_Control_List"))
                    const allPermissions = userDetail.accessControlsDto;
                    menu?.forEach((x: any) => {
                        if (x.id === "dashboards") {
                            permissionMenus.push(menu[0])
                        }

                        if (x.id === "case") {
                            let menus = menu[1];
                            let subMenus = [];
                            allPermissions.forEach((allPermission) => {
                                if (allPermission.roleId === Roles.PRTL_USR || allPermission.roleId === Roles.TECH_RCRS) {
                                    if (allPermission.moduleId === Modules.CASE_MNGT) {
                                        accessControlList.forEach(accessControl => {
                                            if (accessControl?.id === allPermission?.id) {
                                                if (allPermission.accessControlCreate) {
                                                    subMenus.push(menus?.children[0]);
                                                }
                                                if (allPermission.accessControlView) {
                                                    subMenus.push(menus?.children[1]);
                                                }
                                            }
                                        });
                                    }
                                }
                            });
                            if (subMenus?.length > 0) {
                                const uniqueSubmenu = [...new Set(subMenus.filter((item) => item?.id))]
                                menu[1].children = uniqueSubmenu
                                permissionMenus.push(menu[1]);
                            }
                        }

                        if (x.id === "application") {
                            let menus = menu[2]
                            let subMenus = [];
                            subMenus.push(menus?.children[0]);
                            subMenus.push(menus?.children[1]);
                            if (subMenus.length > 0) {
                                const uniqueSubmenu = [...new Set(subMenus.filter((item) => item?.id))]
                                menu[2].children = uniqueSubmenu
                                permissionMenus.push(menu[2]);
                            }
                        }

                        if (x.id === "assessments") {
                            let menus = menu[3]
                            let subMenus = [];
                            subMenus.push(menus?.children[0]);
                            if (subMenus.length > 0) {
                                const uniqueSubmenu = [...new Set(subMenus.filter((item) => item?.id))]
                                menu[3].children = uniqueSubmenu
                                permissionMenus.push(menu[3]);
                            }
                        }

                        if (x.id === "adp-decisions") {
                            let menus = menu[4]
                            let subMenus = [];
                            allPermissions.forEach((allPermission) => {
                                if (allPermission.roleId === Roles.TECH_RCRS) {
                                    subMenus.push(menus?.children[0]);
                                    subMenus.push(menus?.children[1]);
                                }
                            });
                            if (subMenus.length > 0) {
                                const uniqueSubmenu = [...new Set(subMenus.filter((item) => item?.id))]
                                menu[4].children = uniqueSubmenu
                                permissionMenus.push(menu[4]);
                            }
                        }

                        if (x.id === "endorsed-schemes") {
                            let menus = menu[5]
                            let subMenus = [];
                            subMenus.push(menus?.children[0]);
                            if (subMenus.length > 0) {
                                const uniqueSubmenu = [...new Set(subMenus.filter((item) => item?.id))]
                                menu[5].children = uniqueSubmenu
                                permissionMenus.push(menu[5]);
                            }
                        }

                        if (x.id === "certificate" && userDetail?.assessors !== null) {
                            let menus = menu[6]
                            let subMenus = [];
                            allPermissions.forEach((allPermission) => {
                                if (allPermission.roleId === Roles.TECH_RCRS) {
                                    if (allPermission.moduleId === Modules.ASSM_MNGT) {
                                        accessControlList.forEach(accessControl => {
                                            if (accessControl?.id === allPermission?.id) {
                                                if (allPermission?.accessControlView) {
                                                    subMenus.push(menus?.children[0]);
                                                }
                                                if (allPermission?.accessControlView) {
                                                    subMenus.push(menus?.children[1]);
                                                }
                                            }
                                        });
                                    }
                                }
                            });
                            if (subMenus?.length > 0) {
                                const uniqueSubmenu = [...new Set(subMenus?.filter((item) => item?.id))]
                                menu[6].children = uniqueSubmenu
                                permissionMenus.push(menu[6]);
                            }
                        }

                        if (x.id === "schedules") {
                            let menus = menu[7]
                            let subMenus = [];
                            subMenus.push(menus?.children[0]);
                            subMenus.push(menus?.children[1]);
                            if (subMenus.length > 0) {
                                const uniqueSubmenu = [...new Set(subMenus.filter((item) => item?.id))]
                                menu[7].children = uniqueSubmenu
                                permissionMenus.push(menu[7]);
                            }
                        }
                        if (x.id === "community") {
                            let menus = menu[8]
                            let subMenus = [];
                            subMenus.push(menus?.children[0]);
                            if (subMenus.length > 0) {
                                const uniqueSubmenu = [...new Set(subMenus.filter((item) => item?.id))]
                                menu[8].children = uniqueSubmenu
                                permissionMenus.push(menu[8]);
                            }
                        }
                        if (x.id === "legacy-data") {
                            let menus = menu[9];
                            let subMenus = [];
                            allPermissions.forEach((allPermission) => {
                                if (allPermission.roleId === Roles.CAB) {
                                    //if (allPermission.moduleId === Modules.ASSM_MNGT) {
                                    accessControlList.forEach(accessControl => {
                                        if (accessControl?.id === allPermission?.id) {
                                            subMenus.push(menus?.children[0]);
                                            subMenus.push(menus?.children[1]);
                                            subMenus.push(menus?.children[2]);

                                        }
                                    });
                                    //}
                                }
                            });
                            if (subMenus?.length > 0) {
                                const uniqueSubmenu = [...new Set(subMenus.filter((item) => item?.id))]
                                menu[9].children = uniqueSubmenu
                                permissionMenus.push(menu[9]);
                            }
                        }
                        if (x.id === "invoice" && userDetail?.assessors !== null) {
                            let menus = menu[10]
                            let subMenus = [];

                            allPermissions.forEach((allPermission) => {
                                if (allPermission.roleId === Roles.TECH_RCRS) {
                                    accessControlList.forEach(accessControl => {
                                        if (accessControl?.id === allPermission?.id) {
                                            if (allPermission?.accessControlView) {
                                                subMenus.push(menus?.children[0]);
                                                subMenus.push(menus?.children[1]);
                                            }
                                        }
                                    });
                                }
                            });
                            if (subMenus.length > 0) {
                                const uniqueSubmenu = [...new Set(subMenus.filter((item) => item?.id))]
                                menu[10].children = uniqueSubmenu
                                permissionMenus.push(menu[10]);
                            }
                        }
                    });

                }
            })
            data.initialData.forEach((element: any) => {
                element.compact = permissionMenus
                // this.navigation.compact = data.initialData;
            })
        });



        this.roleList = this._staticService.roleList;
        this.moduleList = this._staticService.moduleList;
        // Subscribe to navigation data
        this.userProfile = JSON.parse(this._authService.userProfile);
        this.ShowHideTab();
    }

    ShowHideTab() {
        this.getSwitchAccount();
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }


    // Switch Account
    switchAccount(event) {
        if (this.selectList.cabsDtos != null) {
            let cab = this.selectList.cabsDtos.find(x => x.id === event);
            if (cab) {
                // let localNavigation = JSON.parse(localStorage.getItem('navigation'));
                // this.navigation.compact = localNavigation.default;
                this._commonService.name = cab.abbreviation;

                var cabData = {
                    "jasanz_agencytype": cab.type,
                    "jasanz_agencyid": cab.id,
                    "cab_name": cab.cab_name,
                    "requiresextrascrutiny": cab.requiresextrascrutiny,
                    "certificatefileuploadmethod": cab.certificatefileuploadmethod,
                    "abbreviation": cab.abbreviation,
                    "logo": cab.logo,
                }
                this._authService.switchAccount = JSON.stringify(cabData);
            }
        }
        if (this.selectList.schemesDto != null) {
            let scheme = this.selectList.schemesDto.find(x => x.id === event);

            if (scheme) {
                var schemeNav: any = [];
                this.navigation.compact.filter((i: any) => {
                    if (i.id === "manageCertificate") {
                        i.children.filter((j: any) => {
                            if (j.id === 'manageCertificate.reviewcertificate') {
                                i.children = []
                                i.children.push(j);
                            }
                        })
                        schemeNav.push(i);
                    }
                    else if (i.id !== "setting") {
                        schemeNav.push(i);
                    }
                });
                this.navigation.compact = schemeNav;

                this._commonService.name = scheme.abbreviation;
                var schemeData = {
                    "jasanz_agencytype": scheme.type,
                    "jasanz_agencyid": scheme.id,
                    "cab_name": scheme.schemes_name,
                    "requiresextrascrutiny": false,
                    "certificatefileuploadmethod": false,
                    "abbreviation": scheme.abbreviation,
                    "logo": null,
                }
                this._authService.switchAccount = JSON.stringify(schemeData);
            }

        }

        this._commonService.updateIsActive(event)
        this._router.routeReuseStrategy.shouldReuseRoute = () => false;
        this._router.onSameUrlNavigation = 'reload';
        this._router.navigate(['/dashboards/project']);
    }
    //Get Cab and Scheme List
    getSwitchAccount() {
        this.selectList = JSON.parse(this._authService.userProfile);
        var switchAcc = JSON.parse(this._authService.switchAccount);

        let cabList = [];
        let schemeList = [];
        //Switch account exist both cab and scheme
        if (this.selectList.schemesDto != null && this.selectList.cabsDtos != null) {
            this.switchGroups = [
                {
                    name: 'CAB',
                    switchaccount: [],
                },
                {
                    name: 'Scheme',
                    switchaccount: [],
                },
            ];

            if (this.selectList.cabsDtos != null) {
                this.selectList.cabsDtos.forEach(element => {
                    let obj = {
                        id: element.id,
                        name: element.abbreviation
                    }
                    cabList.push(obj);
                });

                this.switchGroups[0].switchaccount = cabList;

                // if (this.selectList.jasanz_agencyid == null) {
                //     var cabData = {
                //         "jasanz_agencytype": this.selectList.cabsDtos[0]?.type,
                //         "jasanz_agencyid": this.selectList.cabsDtos[0]?.id,
                //         "cab_name": this.selectList.cabsDtos[0]?.schemes_name,
                //         "requiresextrascrutiny": this.selectList.cabsDtos[0]?.requiresextrascrutiny,
                //         "certificatefileuploadmethod": this.selectList.cabsDtos[0]?.certificatefileuploadmethod,
                //         "abbreviation": this.selectList.cabsDtos[0]?.abbreviation,
                //         "logo": this.selectList.cabsDtos[0]?.logo

                //     }
                //     this._authService.switchAccount = JSON.stringify(cabData);
                // }


            }

            if (this.selectList.schemesDto != null) {
                this.selectList.schemesDto.forEach(element => {
                    let obj = {
                        id: element.id,
                        name: element.abbreviation
                    }
                    schemeList.push(obj);
                });
                this.switchGroups[1].switchaccount = schemeList;
                // if (this.selectList.jasanz_agencyid == null) {
                //     var schemeData = {
                //         "jasanz_agencytype": this.selectList.schemesDto[0]?.type,
                //         "jasanz_agencyid": this.selectList.schemesDto[0]?.id,
                //         "cab_name": this.selectList.schemesDto[0]?.schemes_name,
                //         "requiresextrascrutiny": false,
                //         "certificatefileuploadmethod": false,
                //         "logo": null,
                //         "abbreviation": this.selectList.schemesDto[0]?.abbreviation
                //     };
                //     this._authService.switchAccount = JSON.stringify(schemeData);
                // }
            }

            if (switchAcc != null)
                this.switchControl.setValue(switchAcc.jasanz_agencyid);
            return true;
        }

        //Switch account exist only cab
        if (this.selectList.cabsDtos != null) {
            this.switchGroups = [
                {
                    name: 'CAB',
                    switchaccount: [],
                }];

            this.selectList.cabsDtos.forEach(element => {
                let obj = {
                    id: element.id,
                    name: element.abbreviation
                }
                cabList.push(obj);
            });

            if (cabList.length > 0) {

                this.switchGroups[0].switchaccount = cabList;
            }
            // if (this.selectList.jasanz_agencyid == null) {
            //     var cabData = {
            //         "jasanz_agencytype": this.selectList.cabsDtos[0]?.type,
            //         "jasanz_agencyid": this.selectList.cabsDtos[0]?.id,
            //         "cab_name": this.selectList.cabsDtos[0]?.schemes_name,
            //         "requiresextrascrutiny": this.selectList.cabsDtos[0]?.requiresextrascrutiny,
            //         "certificatefileuploadmethod": this.selectList.cabsDtos[0]?.certificatefileuploadmethod,
            //         "abbreviation": this.selectList.cabsDtos[0]?.abbreviation,
            //         "logo": this.selectList.cabsDtos[0]?.logo

            //     }
            //     this._authService.switchAccount = JSON.stringify(cabData);
            // }
            if (switchAcc != null)
                this.switchControl.setValue(switchAcc.jasanz_agencyid);
            return true;

        }
        //Switch account exist only scheme
        if (this.selectList.schemesDto != null) {
            this.switchGroups = [
                {
                    name: 'Scheme',
                    switchaccount: [],
                },
            ];
            this.selectList.schemesDto.forEach(element => {
                let obj = {
                    id: element.id,
                    name: element.abbreviation
                }
                schemeList.push(obj);
            });
            if (schemeList.length > 0) {
                this.switchGroups[0].switchaccount = schemeList;
            }

            // if (switchAcc.jasanz_agencyid != null) {
            //     var schemeData = {
            //         "jasanz_agencytype": switchAcc.jasanz_agencytype,
            //         "jasanz_agencyid": switchAcc.jasanz_agencyid,
            //         "cab_name": switchAcc.cab_name,
            //         "requiresextrascrutiny": false,
            //         "certificatefileuploadmethod": false,
            //         "logo": null,
            //         "abbreviation": switchAcc.abbreviation
            //     };
            //     this._authService.switchAccount = JSON.stringify(schemeData);
            // }
            if (switchAcc != null)
                this.switchControl.setValue(switchAcc.jasanz_agencyid);
            return true;
        }

    }

}
