import { Component, HostListener, isDevMode, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './core/auth/auth.service';
import { CommonService } from './service/common.service';
import { StaticResourcesService } from './service/static-resources.service';
import { Location } from '@angular/common';
import { MsalService } from '@azure/msal-angular';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    /**
     * Constructor
     */
    // Keep me Signed in

    // @HostListener('window:beforeunload')
    // doUnload(): void { // Observable<boolean> | Promise<boolean> | Boolean
    //     this._router.navigate(['/sign-out']);
    // }

    constructor(
        private commonService: CommonService,
        private _authService: AuthService,
        private _router: Router,
        private _staticService: StaticResourcesService,
        private location: Location,
        private _msalService: MsalService,
        private _fuseConfirmationService: FuseConfirmationService
    ) {

    }

    ngOnInit(): void {
        this.setApiUrl();
        this.navigateTo();
    }
    setApiUrl() {

        //Check Local Storage already exist than redirect that url

        const url = './assets/config/api-config.json';
        this.commonService.loadJSONFileData(url).subscribe((response: any) => {

            if (response != null) {
                if (this._authService.userProfile != null) {
                    if (this._authService.accessToken != "undefined") {
                        var currentURL = window.location.href;
                        var url = response.webURL + "sign-in";
                        if (currentURL == response.webURL || currentURL == url)
                            this._router.navigate(['/dashboards/project']);

                    }
                }



                if (isDevMode()) {
                    //Dev URL
                    this.commonService.AuthAPIURL = response.devAuthApi;
                    this.commonService.ReduxAPIURL = response.devReduxAPI;
                    this.commonService.RegisterAPIURL = response.devRegisterAPI;
                    this.commonService.CuprAPIURL = response.devCuprApi;
                    this.commonService.RegisterURL = response.registerDevURL
                }

                //Production URL
                else {
                    this.commonService.AuthAPIURL = response.prodAuthApi;
                    this.commonService.ReduxAPIURL = response.prodReduxAPI;
                    this.commonService.RegisterAPIURL = response.devRegisterAPI;
                    this.commonService.CuprAPIURL = response.prodCuprApi;
                    this.commonService.RegisterURL = response.registerProdURL
                }
                // if (this._authService.isAuthorized()) {
                //     this._staticService.getAllRoleList();
                // }

            }
        }, (error) => {
            console.log(error);
        });
    }

    navigateTo() {
        if (this._authService.isAuthorized() && this._authService.accessToken) {
            const redirectUrl = this.location.path();
            this._router.navigateByUrl('/loading', { state: { redirect: redirectUrl }, replaceUrl: true })
        }
    }

}
