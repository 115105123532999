import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from 'app/core/auth/auth.service';
import { CommonService } from 'app/service/common.service';
import { StaticResourcesService } from 'app/service/static-resources.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  assesmentId: any;
  roleList: any;
  moduleList: any;
  categoryList: any;
  userDetail: any;
  switchAccount: any;
  isCabRole: any;
  isTrRole: any;
  assesmentList: any;
  categoryPermissionList: any;
  userToAccess: any;

  constructor(
    private _staticService: StaticResourcesService,
    private _commonService: CommonService,
    private _authService: AuthService,
    private _router: Router,
    private _msalService: MsalService,
    private _activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.userDetail = JSON.parse(localStorage.getItem("userProfile"));
    this.switchAccount = JSON.parse(localStorage.getItem("switchAccount"));

    let { redirect } = window.history.state;
    if (redirect?.includes('loading')) {
      let previousUrl = localStorage.getItem('previousUrl')
      redirect = previousUrl;
      if (redirect === "" || redirect === undefined) {
        if (this._authService.isAuthorized) {
          this._router.navigate(["/dashboards/project"])
        }
        else {
          localStorage.clear();
          this._router.navigate(["support/sign-in"])
        }
      } else {
        // if (redirect?.includes('decisionId')) {
        //   this._router.navigateByUrl(redirect, { replaceUrl: true })
        // }
        // else {
          // this._staticService.rolePermission.next(true);
          // this._staticService.subscription = this._staticService.rolePermission
          //   .subscribe(resp => {
          //     if (resp) {
          if (redirect?.includes('assessmentId')) {
            let aId = redirect?.split('assessmentId=')
            let bId = aId[1]?.split('&')
            let assessmentId = bId[0];
            forkJoin([
              this._staticService.getCategoryPermissionSet(),
              this._staticService.getMasterData(),
              this._commonService.getResult(this._commonService.ReduxAPIUR + "Assessment/GetAssessmentScopeAndDetails?assessmentId=" + assessmentId, "")
            ]).subscribe((res: any) => {
              this._staticService.categoryPermissionList = res[0];
              this._staticService.roleList = res[1].data.roleData;
              this._staticService.moduleList = res[1].data.moduleData;
              this._staticService.categoryList = res[1].data.categoryData;
              this._staticService.masterData.next(res[1].data);
              this._commonService.assessmentDetails.next(res[2].data);
              this._staticService.assessmentDetail = res[2].data;

              this.categoryPermissionList = res[0];
              this.roleList = res[1].data.roleData;
              this.moduleList = res[1].data.moduleData;
              this.categoryList = res[1].data.categoryData;

              this.isCabRole = this.userDetail?.roleDto.find((x: any) => x.id === this.roleList?.CAB);
              this.isTrRole = this.userDetail?.roleDto.find((x: any) => x.id === this.roleList?.Technical_Resource);
              this.userToAccess = res[1]?.data?.userAccesses?.find(item => item?.id === this.userDetail?.contact_id)

              let allAtm: any = [];
              res[2].data.others?.forEach((e) => {
                let abc = e?.name?.split('- ')
                let atmName = abc?.slice(1).join('- ');
                let obj = {
                  id: e.categoryId,
                  name: e.categoryName,
                  atmId: e.id,
                  atmName: atmName,
                  assessmentId: e.assessmentId,
                  resultId: e.resultId,
                  trId: e.trId,
                  trName: e.trName
                }
                allAtm.push(obj);
              })

              let loggedInAtm = allAtm?.filter((item) => item?.trId === this.userDetail?.assessors?.technicalResourceId)
              this._commonService.loggedInAtm = loggedInAtm;
              let categoryFound = [];
              loggedInAtm?.forEach((elem) => {
                this.categoryPermissionList?.allTeamMember?.forEach((elemen) => {
                  if (elem.id === elemen) {
                    categoryFound.push(elemen)
                  }
                })
              })
              if (this.isTrRole) {
                if (categoryFound?.length > 0) {
                  this._commonService.atmList = allAtm;
                  if (redirect === "" || redirect === undefined) {
                    if (this._authService.isAuthorized) {
                      this._router.navigate(["/dashboards/project"])
                    }
                    else {
                      localStorage.clear();
                      this._router.navigate(["support/sign-in"])
                    }
                  } else {
                    this._router.navigateByUrl(redirect, { replaceUrl: true })
                  }
                }
                else if (categoryFound?.length == 0) {
                  this._commonService.atmList = loggedInAtm;
                  if (redirect === "" || redirect === undefined) {
                    if (this._authService.isAuthorized) {
                      this._router.navigate(["/dashboards/project"])
                    }
                    else {
                      localStorage.clear();
                      this._router.navigate(["support/sign-in"])
                    }
                  } else {
                    this._router.navigateByUrl(redirect, { replaceUrl: true })
                  }
                }
              }
              else if (this.isCabRole) {
                this._commonService.atmList = allAtm;
                if (redirect === "" || redirect === undefined) {
                  if (this._authService.isAuthorized) {
                    this._router.navigate(["/dashboards/project"])
                  }
                  else {
                    localStorage.clear();
                    this._router.navigate(["support/sign-in"])
                  }
                } else {
                  this._router.navigateByUrl(redirect, { replaceUrl: true })

                }
              }
              else if (this.userToAccess) {
                this._commonService.atmList = allAtm;
                if (redirect === "" || redirect === undefined) {
                  if (this._authService.isAuthorized) {
                    this._router.navigate(["/dashboards/project"])
                  }
                  else {
                    localStorage.clear();
                    this._router.navigate(["support/sign-in"])
                  }
                } else {
                  this._router.navigateByUrl(redirect, { replaceUrl: true })

                }
              }
            });
          }
          else {
            forkJoin([
              this._staticService.getCategoryPermissionSet(),
              this._staticService.getMasterData(),
            ]).subscribe((res: any) => {
              this._staticService.categoryPermissionList = res[0];
              this._staticService.roleList = res[1]?.data.roleData;
              this._staticService.moduleList = res[1]?.data.moduleData;
              this._staticService.categoryList = res[1]?.data.categoryData;
              this._staticService.masterData.next(res[1]?.data);

              this.categoryPermissionList = res[0];
              this.roleList = res[1]?.data.roleData;
              this.moduleList = res[1]?.data.moduleData;
              this.categoryList = res[1]?.data.categoryData;

              this.isCabRole = this.userDetail?.roleDto.find((x: any) => x.id === this.roleList?.CAB);
              this.isTrRole = this.userDetail?.roleDto.find((x: any) => x.id === this.roleList?.Technical_Resource);

              if (redirect === "" || redirect === undefined) {
                if (this._authService.isAuthorized) {
                  this._router.navigate(["/dashboards/project"])
                }
                else {
                  localStorage.clear();
                  this._router.navigate(["support/sign-in"])
                }
              } else {
                this._router.navigateByUrl(redirect, { replaceUrl: true })
              }
            });
          }

          //   }
          // });
        // }
        localStorage.removeItem('previousUrl')
      }
    } else {
      localStorage.setItem('previousUrl', redirect)
      // if (redirect?.includes('decisionId')) {
      //   this._router.navigateByUrl(redirect, { replaceUrl: true })
      // }
      // else {
      // this._staticService.rolePermission.next(true);
      // this._staticService.subscription = this._staticService.rolePermission
      //   .subscribe(resp => {
      //     if (resp) {
      if (redirect?.includes('assessmentId')) {
        let aId = redirect?.split('assessmentId=')
        let bId = aId[1]?.split('&')
        let assessmentId = bId[0];
        forkJoin([
          this._staticService.getCategoryPermissionSet(),
          this._staticService.getMasterData(),
          this._commonService.getResult(this._commonService.ReduxAPIUR + "Assessment/GetAssessmentScopeAndDetails?assessmentId=" + assessmentId, "")
        ]).subscribe((res: any) => {
          this._staticService.categoryPermissionList = res[0];
          this._staticService.roleList = res[1].data.roleData;
          this._staticService.moduleList = res[1].data.moduleData;
          this._staticService.categoryList = res[1].data.categoryData;
          this._staticService.masterData.next(res[1].data);
          this._commonService.assessmentDetails.next(res[2].data);
          this._staticService.assessmentDetail = res[2].data;

          this.categoryPermissionList = res[0];
          this.roleList = res[1].data.roleData;
          this.moduleList = res[1].data.moduleData;
          this.categoryList = res[1].data.categoryData;

          this.isCabRole = this.userDetail?.roleDto.find((x: any) => x.id === this.roleList?.CAB);
          this.isTrRole = this.userDetail?.roleDto.find((x: any) => x.id === this.roleList?.Technical_Resource);
          this.userToAccess = res[1]?.data?.userAccesses?.find(item => item?.id === this.userDetail?.contact_id)


          let allAtm: any = [];
          res[2].data.others?.forEach((e) => {
            let abc = e?.name?.split('- ')
            let atmName = abc?.slice(1).join('- ');
            let obj = {
              id: e.categoryId,
              name: e.categoryName,
              atmId: e.id,
              atmName: atmName,
              assessmentId: e.assessmentId,
              resultId: e.resultId,
              trId: e.trId,
              trName: e.trName
            }
            allAtm.push(obj);
          })

          let loggedInAtm = allAtm?.filter((item) => item?.trId === this.userDetail?.assessors?.technicalResourceId)
          this._commonService.loggedInAtm = loggedInAtm;
          let categoryFound = [];
          loggedInAtm?.forEach((elem) => {
            this.categoryPermissionList?.allTeamMember?.forEach((elemen) => {
              if (elem.id === elemen) {
                categoryFound.push(elemen)
              }
            })
          })
          if (this.isTrRole) {
            if (categoryFound?.length > 0) {
              this._commonService.atmList = allAtm;
              if (redirect === "" || redirect === undefined) {
                if (this._authService.isAuthorized) {
                  this._router.navigate(["/dashboards/project"])
                }
                else {
                  localStorage.clear();
                  this._router.navigate(["support/sign-in"])
                }
              } else {
                this._router.navigateByUrl(redirect, { replaceUrl: true })
              }
            }
            else if (categoryFound?.length == 0) {
              this._commonService.atmList = loggedInAtm;
              if (redirect === "" || redirect === undefined) {
                if (this._authService.isAuthorized) {
                  this._router.navigate(["/dashboards/project"])
                }
                else {
                  localStorage.clear();
                  this._router.navigate(["support/sign-in"])
                }
              } else {
                this._router.navigateByUrl(redirect, { replaceUrl: true })
              }
            }
          }
          else if (this.isCabRole) {
            this._commonService.atmList = allAtm;
            if (redirect === "" || redirect === undefined) {
              if (this._authService.isAuthorized) {
                this._router.navigate(["/dashboards/project"])
              }
              else {
                localStorage.clear();
                this._router.navigate(["support/sign-in"])
              }
            } else {
              this._router.navigateByUrl(redirect, { replaceUrl: true })

            }
          }
          else if (this.userToAccess) {
            this._commonService.atmList = allAtm;
            if (redirect === "" || redirect === undefined) {
              if (this._authService.isAuthorized) {
                this._router.navigate(["/dashboards/project"])
              }
              else {
                localStorage.clear();
                this._router.navigate(["support/sign-in"])
              }
            } else {
              this._router.navigateByUrl(redirect, { replaceUrl: true })

            }
          }
        });
      }
      else {
        forkJoin([
          this._staticService.getCategoryPermissionSet(),
          this._staticService.getMasterData(),
        ]).subscribe((res: any) => {
          this._staticService.categoryPermissionList = res[0];
          this._staticService.roleList = res[1]?.data.roleData;
          this._staticService.moduleList = res[1]?.data.moduleData;
          this._staticService.categoryList = res[1]?.data.categoryData;
          this._staticService.masterData.next(res[1]?.data);

          this.categoryPermissionList = res[0];
          this.roleList = res[1]?.data.roleData;
          this.moduleList = res[1]?.data.moduleData;
          this.categoryList = res[1]?.data.categoryData;

          this.isCabRole = this.userDetail?.roleDto.find((x: any) => x.id === this.roleList?.CAB);
          this.isTrRole = this.userDetail?.roleDto.find((x: any) => x.id === this.roleList?.Technical_Resource);

          if (redirect === "" || redirect === undefined) {
            if (this._authService.isAuthorized) {
              this._router.navigate(["/dashboards/project"])
            }
            else {
              localStorage.clear();
              this._router.navigate(["support/sign-in"])
            }
          } else {
            this._router.navigateByUrl(redirect, { replaceUrl: true })
          }
        });
        // }

        //   }
        // });
      }
    }
  }
}
