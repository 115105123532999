import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};


import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import {  MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { msalConfig } from './config/auth.config';
import { MsalGuard } from './core/auth/guards/msal.guard';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { CommonService } from './service/common.service';
import { ToastrModule } from 'ngx-toastr';
import { EllipsisModule } from 'ngx-ellipsis';
import { LoaderComponent } from './loader/loader.component';
import { RespondQueriesComponent } from './modules/admin/pages/shared-component/respond-queries/respond-queries.component';
//import { CanDeactivateGuard } from './can-deactivate/can-deactivate.guard';




/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(msalConfig);
}
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        LoaderComponent,
        RespondQueriesComponent
       ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
    

        RouterModule.forRoot(appRoutes, routerConfig),
        //  Translate
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,
    
        // Layout module of your application
        LayoutModule,
      // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({}),
        EllipsisModule
     
    ], providers: [DatePipe,
       // CanDeactivateGuard,
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        //{
        //    provide: LocationStrategy,
        //    useClass: HashLocationStrategy
        //},
        //{
        //    provide: HTTP_INTERCEPTORS,
        //    useClass: MsalInterceptor,
        //    multi: true
        //},
        CommonService,
        MsalService,
        MsalGuard,
        MsalBroadcastService,

    ],
    bootstrap: [
        AppComponent
    ],
  
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ]
})
export class AppModule {
}
