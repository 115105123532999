import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FuseDrawerModule } from '@fuse/components/drawer';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMomentDateModule, MomentDateModule } from '@angular/material-moment-adapter';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ToastrModule } from 'ngx-toastr';
import { MatStepperModule } from '@angular/material/stepper';
import { CommentEditComponent } from './comment-edit/comment-edit.component';
import { UserPermissionsDirective } from 'app/directives/user-permissions-directive';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatCardModule } from '@angular/material/card';
import { MatSliderModule } from '@angular/material/slider';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { RolePermissionsDirective } from './role-permission.directive';
import { CategoryPermissionDirective } from './category-permission.directive';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgxMatTimepickerModule } from "ngx-mat-timepicker";
import { FuseAlertModule } from '@fuse/components/alert';
import { AngularEditorModule } from '@kolkov/angular-editor';

@NgModule({
  imports: [
    ToastrModule.forRoot(

    ),     // ToastrModule added
    CommonModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    TranslateModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDividerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatSidenavModule,
    FuseDrawerModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatStepperModule,
    MomentDateModule,
    MatExpansionModule,
    NgSelectModule,
    MatCardModule,
    MatMomentDateModule,
    MatSliderModule,
    FullCalendarModule,
    CKEditorModule,
    MatAutocompleteModule,
    NgxMatTimepickerModule,
    FuseAlertModule,
    AngularEditorModule,
  ],
  exports: [
    CommonModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    TranslateModule,
    MatDividerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatSidenavModule,
    FuseDrawerModule,
    MatRadioModule,
    MatCheckboxModule,
    MatStepperModule,
    MomentDateModule,
    UserPermissionsDirective,
    MatExpansionModule,
    NgSelectModule,
    MatCardModule,
    MatMomentDateModule,
    MatSliderModule,
    FullCalendarModule,
    CKEditorModule,
    RolePermissionsDirective,
    CategoryPermissionDirective,
    MatNativeDateModule,
    MatAutocompleteModule,
    NgxMatTimepickerModule,
    FuseAlertModule,
    AngularEditorModule,
  ],
  declarations: [
    CommentEditComponent,
    UserPermissionsDirective,
    RolePermissionsDirective,
    CategoryPermissionDirective
  ]
})
export class SharedModule {
}
