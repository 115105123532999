import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    idToken: any;
    /**
     * Constructor
     */
    constructor(private _authService: AuthService, private toastrService:ToastrService) {
    }

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


        // Clone the request object
        let newReq = req.clone();

        // Request
        //
        // If the access token didn't expire, add the Authorization header.
        // We won't add the Authorization header if the access token expired.
        // This will force the server to return a "401 Unauthorized" response
        // for the protected API routes which our response interceptor will
        // catch and delete the access token from the local storage while logging
        // the user out from the app.

        if (this._authService.accessToken != "undefined") {
            if (this._authService.accessToken && !AuthUtils.isTokenExpired(this._authService.accessToken)) {

                newReq = req.clone({
                    headers: req.headers.set('Authorization', 'Bearer ' + this._authService.accessToken)
                });
            }
        }
        else { this._authService.signOut(); }





        // Response
        return next.handle(newReq).pipe(
            catchError((error) => {
                // Catch "401 Unauthorized" responses
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    this._authService.signOut();
                }
                if (error instanceof HttpErrorResponse && error.status === 500) {
                    if (error.error.isSuccessful === false) {
                        this.toastrService.error(error.error.messageDetail?.message, 'Error', {
                            positionClass: 'toast-top-right',
                            closeButton: true,
                        });
                    }
                    return throwError(error);
                }
                return throwError(error);
            })
        );
    }
}
