import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { AuthService } from '../core/auth/auth.service';



@Directive({

  selector: '[appUserPermissions]'

})

export class UserPermissionsDirective {

    constructor(

        private templateRef: TemplateRef<any>,

        private authService: AuthService,

        private viewContainer: ViewContainerRef

    ) { }



    userPermissions!: Permissions[]



    @Input()

    set appUserPermissions(permissions: Permissions[]) {

        if (!permissions || !permissions.length) {

            throw new Error('Permissions value is empty or missed');

        }



        this.userPermissions = permissions;

    }



    ngOnInit() {

        let hasAccess = false;

        if (this.authService.isAuthorized() && this.userPermissions) {

            hasAccess = this.userPermissions.some((p: any) => {

                return this.authService.hasPermission(p);

            });

        }



        if (hasAccess) {

            this.viewContainer.createEmbeddedView(this.templateRef);

        } else {

            this.viewContainer.clear();

        }

    }

}