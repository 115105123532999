import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../core/auth/auth.service';

@Directive({
    selector: '[appRolePermissions]'
})

export class RolePermissionsDirective {

    constructor(
        private templateRef: TemplateRef<any>,
        private authService: AuthService,
        private viewContainer: ViewContainerRef
    ) { }
    userPermissions!: Permissions

    @Input()
    set appRolePermissions(permissions: Permissions) {
        if (!permissions) {
            throw new Error('Permissions value is empty or missed');
        }
        this.userPermissions = permissions;
    }

    ngOnInit() {
        let hasAccess = false;
        if (this.authService.isAuthorized() && this.userPermissions) {
            hasAccess = this.authService.hasPermission(this.userPermissions);
        }

        if (hasAccess) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}